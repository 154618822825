import * as React from 'react';
import { IconType } from 'react-icons';
import styled, { css } from 'styled-components';
import { Tooltip } from '..';

const positionStyles = css`
  position: absolute;
  left: 0;
  bottom: 0;
`;

const Button = styled.button`
  border: 2px solid ${({ theme }) => theme.colorsPallete.blue};
  cursor: pointer;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 8px 0 0;
  font-size: 1.8rem;

  background: ${({ theme }) => theme.colorsPallete.blue};
  color: white;
  box-shadow: 0 0 3px hsl(0, 0%, 65%);
`;

export interface ButtonIconProps {
  Icon: IconType;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  tooltip?: string;
}
export const ButtonIcon: React.FC<ButtonIconProps> = ({
  Icon,
  onClick,
  tooltip,
}: ButtonIconProps) => (
  <>
    <Tooltip tooltip={tooltip} customChildrenStyles={positionStyles}>
      <Button onClick={onClick}>
        <Icon />
      </Button>
    </Tooltip>
  </>
);
