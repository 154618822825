import * as React from 'react';
import styled from 'styled-components';
import { SummaryList } from '..';
import { useTypedSelector } from '../../../../hooks/use-typed-selector';
import legend from '../../../../img/legend.png';

const MainWrapper = styled.div`
  align-items: center;
  margin-top: 15px;
`;

const Header = styled.h3`
  text-align: center;
`;

const LegendImg = styled.img`
  max-width: 180px;
`;

const ElementsWrapper = styled.div`
  padding: 0 5px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.screenWidthSize.desktop.WXGA}) {
    flex-direction: row-reverse;
  }
`;

export interface TRNApropertiesLegendProps {}
export const TRNApropertiesLegend: React.FC<TRNApropertiesLegendProps> = () => {
  const tRNApropertiesSingleLinkState = useTypedSelector(
    ({ tRNApropertiesSingleLink }) => tRNApropertiesSingleLink,
  );
  return (
    <MainWrapper>
      <Header>
        tRNA secondary structures rendered by NAVIEW &copy; 1988 Robert E. Bruccoleri.
      </Header>
      <ElementsWrapper>
        <LegendImg src={legend} alt="tRNA secondary structures legend" />
        {tRNApropertiesSingleLinkState?.summary && (
          <SummaryList summary={tRNApropertiesSingleLinkState.summary} />
        )}
      </ElementsWrapper>
    </MainWrapper>
  );
};
