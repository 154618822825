import * as React from 'react';
import styled, { css } from 'styled-components';
import { TiArrowSortedDown, TiArrowSortedUp } from 'react-icons/ti';

const DescriptionWrapper = styled.div`
  position: relative;
`;

const visibleText = css`
  overflow: visible;
  display: block;
`;

const iconStyles = css`
  font-size: 22px;
  position: absolute;
  top: 1px;
  right: 2px;
`;

interface DescriptionProps {
  showAll: boolean;
}

const Description = styled.p<DescriptionProps>`
  padding: 5px 20px 0px 15%;
  text-align: justify;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 30px;

  ${({ showAll }) =>
    showAll &&
    css`
      ${visibleText}
    `}

  @media (min-width: ${(props) => props.theme.screenWidthSize.desktop.XGA}) {
    padding-right: 15%;
    margin-bottom: 15px;
    ${visibleText}
  }
`;

const ReadMoreBtn = styled.button`
  position: absolute;
  bottom: 0;
  right: 20px;
  padding: 1px 25px 1px 10px;
  font-size: 1.3rem;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: 2px solid black;
  border-radius: 20px;
  font-family: ${({ theme }) => theme.fontFamily.main};
  text-transform: uppercase;

  @media (min-width: ${(props) => props.theme.screenWidthSize.desktop.XGA}) {
    display: none;
  }
`;

const StyledIconDown = styled(TiArrowSortedDown)`
  ${iconStyles}
`;

const StyledIconUp = styled(TiArrowSortedUp)`
  ${iconStyles}
`;

export interface DescriptionParagraphProps {
  children: React.ReactNode;
  disableReadMoreBtn?: boolean;
}

export const DescriptionParagraph: React.FC<DescriptionParagraphProps> = ({
  children,
  disableReadMoreBtn,
}: DescriptionParagraphProps) => {
  const [showAll, setShowAll] = React.useState(!!disableReadMoreBtn);
  const handleClick: React.MouseEventHandler<HTMLButtonElement> = () => setShowAll(!showAll);
  const btnLabel = !showAll ? 'Read more' : 'Read less';
  const icon = !showAll ? <StyledIconDown /> : <StyledIconUp />;

  return (
    <DescriptionWrapper>
      <Description showAll={showAll}>{children}</Description>
      {!disableReadMoreBtn && (
        <ReadMoreBtn onClick={handleClick}>
          {btnLabel} {icon}
        </ReadMoreBtn>
      )}
    </DescriptionWrapper>
  );
};
