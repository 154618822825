import { combineReducers } from 'redux';
import canisSNPFinderReducer from './canisSNPFinderReducer';
import dictionariesReducer from './dictionariesReducer';
import errorsReducer from './errorsReducer';
import generateXLSXfileReducer from './generateXLSXfileReducer';
import tRNApropertiesReducer from './tRNApropertiesReducer';
import tRNApropertiesSingleLinkReducer from './tRNApropertiesSingleLinkReducer';
import dnaLoaderReducer from './dnaLoaderReducer';
import mRNApropertiesReducer from './mRNApropertiesReducer';
import mRNApropertiesSingleLinkReducer from './mRNApropertiesSingleLinkReducer';
import proteinPropertiesReducer from './proteinPropertiesReducer';
import proteinPropertiesSingleLinkReducer from './proteinPropertiesSingleLinkReducer';

const reducers = combineReducers({
  canisSNPFinder: canisSNPFinderReducer,
  dictionaries: dictionariesReducer,
  errors: errorsReducer,
  generateXLSXfile: generateXLSXfileReducer,
  tRNAproperties: tRNApropertiesReducer,
  tRNApropertiesSingleLink: tRNApropertiesSingleLinkReducer,
  dnaLoader: dnaLoaderReducer,
  mRNAproperties: mRNApropertiesReducer,
  mRNApropertiesSingleLink: mRNApropertiesSingleLinkReducer,
  proteinProperties: proteinPropertiesReducer,
  proteinPropertiesSingleLink: proteinPropertiesSingleLinkReducer,
});

export default reducers;

export type RootState = ReturnType<typeof reducers>;
