import styled, { css } from 'styled-components';

interface ContentFlexWrapperProps {
  flexDirection?: 'column' | 'row';
  flexGrow?: boolean;
  color?: string;
  flexCenter?: boolean;
  customFontFamily?: boolean;
  darkBackground?: boolean;
  justifyContent?: string;
  alignItems?: string;
  bottomMargin?: boolean;
  flexWrap?: string;
}

export const ContentFlexWrapper = styled.div<ContentFlexWrapperProps>`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection || 'column'};

  ${({ flexGrow }) =>
    flexGrow &&
    css`
      flex-grow: 1;
    `}

  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}

  ${({ color }) =>
    color &&
    css`
      color: ${color};
    `}

  ${({ flexCenter }) =>
    flexCenter &&
    css`
      justify-content: center;
      align-items: center;
    `}

  ${({ customFontFamily }) =>
    customFontFamily &&
    css`
      font-family: ${({ theme }) => theme.fontFamily.header};
    `}

  ${({ darkBackground }) =>
    darkBackground &&
    css`
      background-color: hsla(0, 0%, 0%, 0.5);
    `}

  ${({ bottomMargin }) =>
    bottomMargin &&
    css`
      margin-bottom: 8px;
    `}

  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `}
`;
