/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import styled, { CSSProp } from 'styled-components';
import { usePopperTooltip } from 'react-popper-tooltip';
import 'react-popper-tooltip/dist/styles.css';

export type Placement =
  | 'auto'
  | 'auto-start'
  | 'auto-end'
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'left'
  | 'left-start'
  | 'left-end';

export const StyledTooltipContainer = styled.div``;

export const StyledTooltipArrow = styled.div``;

interface ChildrenWrapperProps {
  customChildrenStyles?: CSSProp;
}

export const ChildrenWrapper = styled.div<ChildrenWrapperProps>`
  display: inline-block;
  ${({ customChildrenStyles }) => customChildrenStyles && customChildrenStyles}
`;

export interface TooltipProps {
  children: React.ReactNode;
  placement?: Placement;
  tooltip?: string;
  customChildrenStyles?: CSSProp;
}
export const Tooltip: React.FC<TooltipProps> = ({
  placement = 'top',
  children,
  tooltip,
  customChildrenStyles,
}: TooltipProps) => {
  const { getArrowProps, getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      placement,
    });

  return (
    <>
      <ChildrenWrapper ref={setTriggerRef} customChildrenStyles={customChildrenStyles}>
        {children}
      </ChildrenWrapper>
      {tooltip && visible && (
        <StyledTooltipContainer
          ref={setTooltipRef}
          {...getTooltipProps({
            className: 'tooltip-container',
          })}
        >
          <StyledTooltipArrow
            {...getArrowProps({
              className: 'tooltip-arrow',
            })}
          />
          {tooltip}
        </StyledTooltipContainer>
      )}
    </>
  );
};
