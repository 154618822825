import * as React from 'react';
import { SummaryItem, SUMMARY_ID } from '../../../../state/tRNApropertiesSingleLink';
import { getKeyValue } from '../../../../utils';
import { CustomTextColor } from '../../molecules';
import { Element, Header, List, ListItem, Wrapper } from './style';

type ColorsTitleMods = {
  [key in SUMMARY_ID]?: CustomTextColor;
};

const COLORS_TITLE_MODS: ColorsTitleMods = {
  [SUMMARY_ID.TRANSITIONS]: 'orange',
  [SUMMARY_ID.TRANSVERATIONS]: 'red',
  [SUMMARY_ID.GAPS]: 'brown',
};

export interface SummaryListProps {
  summary: SummaryItem[];
  margin?: string;
}
export const SummaryList: React.FC<SummaryListProps> = ({ summary, margin }: SummaryListProps) => (
  <Wrapper margin={margin}>
    <Header>Summary</Header>
    <List>
      {summary.map(({ value, title, id }, index) => {
        const boldText = index === summary.length - 1;
        let customColor: CustomTextColor | undefined;
        if (id in COLORS_TITLE_MODS) customColor = getKeyValue(COLORS_TITLE_MODS)(id);
        return (
          <ListItem key={id}>
            <Element customColor={customColor}>{title}</Element>
            <Element boldText={boldText}>{value}</Element>
          </ListItem>
        );
      })}
    </List>
  </Wrapper>
);
