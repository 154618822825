export enum AvailableProteinColumnIDs {
  GENE = 'gene',
  ABBREVIATION = 'abbreviation',
  DOG_START = 'dogStart',
  DOG_END = 'dogEnd',
  HUMAN_START = 'humanStart',
  HUMAN_END = 'humanEnd',
  LENGTH_DOG = 'lengthDog',
  LENGTH_HUMAN = 'lengthHuman',
  TRANSITIONS = 'transitions',
  TRANSVERATIONS = 'transverations',
  GAPS = 'gaps',
  IDENTICAL_POSITIONS = 'identicalPositions',
  SUM_OF_POSITIONS = 'sumOfPositions',
  PERCENTAGE_GENE_HOMOLOGY = 'percentageGeneHomology',
  IDENTICAL_AMINOACID = 'identicalAminoacid',
  CONSERVATIVE_REPLACEMENT = 'conversativeReplacement',
  NONCONSERVATIVE_REPLACEMENT = 'nonconversativeReplacement',
  PROTEIN_LENGTH = 'proteinLength',
  AMINOACID_PERCENTAGE_HOMOLOGY = 'aminoacidPercentageHomology',
}

export interface ProteinPropertiesElement {
  [AvailableProteinColumnIDs.GENE]: string;
  [AvailableProteinColumnIDs.ABBREVIATION]: string;
  [AvailableProteinColumnIDs.DOG_START]: string;
  [AvailableProteinColumnIDs.DOG_END]: string;
  [AvailableProteinColumnIDs.HUMAN_START]: string;
  [AvailableProteinColumnIDs.HUMAN_END]: string;
  [AvailableProteinColumnIDs.LENGTH_DOG]: string;
  [AvailableProteinColumnIDs.LENGTH_HUMAN]: string;
  [AvailableProteinColumnIDs.TRANSITIONS]: string;
  [AvailableProteinColumnIDs.TRANSVERATIONS]: string;
  [AvailableProteinColumnIDs.GAPS]: string;
  [AvailableProteinColumnIDs.IDENTICAL_POSITIONS]: string;
  [AvailableProteinColumnIDs.SUM_OF_POSITIONS]: string;
  [AvailableProteinColumnIDs.PERCENTAGE_GENE_HOMOLOGY]: string;
  [AvailableProteinColumnIDs.IDENTICAL_AMINOACID]: string;
  [AvailableProteinColumnIDs.CONSERVATIVE_REPLACEMENT]: string;
  [AvailableProteinColumnIDs.NONCONSERVATIVE_REPLACEMENT]: string;
  [AvailableProteinColumnIDs.PROTEIN_LENGTH]: string;
  [AvailableProteinColumnIDs.AMINOACID_PERCENTAGE_HOMOLOGY]: string;
}

export enum URL_PROTEIN_PARAMS {
  LINK_ID = 'linkID',
}

export interface UrlParams {
  [URL_PROTEIN_PARAMS.LINK_ID]: string;
}
