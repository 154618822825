/* eslint-disable object-curly-newline */
import * as React from 'react';
import styled from 'styled-components';
import { VscWarning } from 'react-icons/vsc';
import { AiOutlineMail } from 'react-icons/ai';
import { ISSUE_EMAIL } from '../../../../global/types';

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
`;

const StyledWarningIcon = styled(VscWarning)`
  font-size: 3rem;
`;

const MainWrapper = styled.div`
  font-size: 1.3rem;
  font-family: Arial, Helvetica, sans-serif;
`;

const MainTitle = styled.h3`
  text-align: center;
`;

const MainLabel = styled.p``;

const InfoWrapper = styled.div`
  font-style: italic;
`;

const InfoDescription = styled.p`
  font-size: 1.4rem;
  margin: 5px 0;
`;

const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledEmailIcon = styled(AiOutlineMail)`
  margin-right: 5px;
  font-size: 2rem;
`;

const MailLink = styled.a`
  color: white;
  font-weight: bold;
`;

export interface ErrorToastMessageProps {
  label: string;
}

export const ErrorToastMessage: React.FC<ErrorToastMessageProps> = ({
  label,
}: ErrorToastMessageProps) => (
  <>
    <HeaderWrapper>
      <StyledWarningIcon />
      <MainWrapper>
        <MainTitle>Ups! Something went wrong...</MainTitle>
        <MainLabel>{label}</MainLabel>
      </MainWrapper>
    </HeaderWrapper>
    <InfoWrapper>
      <InfoDescription>If the problem still exists, please contact:</InfoDescription>
      <ContactWrapper>
        <StyledEmailIcon />
        <MailLink href={`mailto:${ISSUE_EMAIL}`}>{ISSUE_EMAIL}</MailLink>
      </ContactWrapper>
    </InfoWrapper>
  </>
);

export const setErrorToastMessage = (label: string) => <ErrorToastMessage label={label} />;
