/* eslint-disable object-curly-newline */
import { CustomTextColor } from '../../components/UI/molecules';
import {
  AvailableTRNAsingleLinkIDs,
  TRNApropertiesSingleLinkElement,
} from '../../state/tRNApropertiesSingleLink';

export const getCustomTextColor = (values: TRNApropertiesSingleLinkElement) => {
  let color: CustomTextColor | null = null;
  const firstCondition = values[AvailableTRNAsingleLinkIDs.DOG_5_3_STRAND];
  const secondCondition = values[AvailableTRNAsingleLinkIDs.HUMAN_5_3_STRAND];
  if (
    (firstCondition === 'A' && secondCondition === 'G') ||
    (firstCondition === 'G' && secondCondition === 'A') ||
    (firstCondition === 'C' && secondCondition === 'T') ||
    (firstCondition === 'T' && secondCondition === 'C')
  ) {
    color = 'orange';
  } else if (
    (firstCondition === 'A' && secondCondition === 'T') ||
    (firstCondition === 'T' && secondCondition === 'A') ||
    (firstCondition === 'G' && secondCondition === 'C') ||
    (firstCondition === 'C' && secondCondition === 'G') ||
    (firstCondition === 'G' && secondCondition === 'T') ||
    (firstCondition === 'T' && secondCondition === 'G') ||
    (firstCondition === 'A' && secondCondition === 'C') ||
    (firstCondition === 'C' && secondCondition === 'A')
  ) {
    color = 'red';
  } else if (
    (firstCondition === '-' && ['A', 'T', 'C', 'G'].includes(secondCondition)) ||
    (['A', 'T', 'C', 'G'].includes(firstCondition) && secondCondition === '-')
  ) {
    color = 'brown';
  }
  return color;
};
