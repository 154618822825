import * as React from 'react';
import { PageTemplate } from '../../templates';
import { StyledMenuList } from '../../UI/organisms';
import { MainHeader, StyledCircleImage, StyledLinkProps } from '../../UI/atoms';
import { RoutingLabels, RoutingURLs } from '../../../routing';

const menuLinks: StyledLinkProps[] = [
  {
    to: RoutingURLs.CANIS_SNP_FINDER,
    label: RoutingLabels.CANIS_SNP_FINDER,
  },
  {
    to: RoutingURLs.TRNA_PROPERTIES,
    label: RoutingLabels.TRNA_PROPERTIES,
  },
  {
    to: RoutingURLs.MRNA_PROPERTIES,
    label: RoutingLabels.MRNA_PROPERTIES,
  },
  {
    to: RoutingURLs.PROTEIN_PROPERTIES,
    label: RoutingLabels.PROTEIN_PROPERTIES,
  },
  // {
  //   to: '/link5',
  //   label: 'Sequence homology',
  // },
];

export interface WelcomePageProps {}
export const WelcomePage: React.FC<WelcomePageProps> = () => {
  React.useEffect(() => {
    document.title = 'Canis MitoSNP';
  }, []);
  return (
    <PageTemplate>
      <MainHeader>Canis MitoSNP - canine mitochondrial database</MainHeader>
      <StyledMenuList links={menuLinks} />
      <StyledCircleImage />
    </PageTemplate>
  );
};
