/* eslint-disable object-curly-newline */
import * as React from 'react';
import { DescriptionParagraph, CustomTable, CustomizeTableRow } from '../../UI/molecules';
import { PageTemplate } from '../../templates';
import { MainHeader, PreviousPageBtn } from '../../UI/atoms';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { RoutingLabels, RoutingURLs } from '../../../routing';
import { useActions } from '../../../hooks/use-actions';
import { getCellCustomStyle } from '../../../utils';
import {
  AvailableProteinColumnIDs,
  ProteinPropertiesElement,
} from '../../../state/proteinProperties';

export interface ProteinPropertiesProps {}
export const ProteinProperties: React.FC<ProteinPropertiesProps> = () => {
  const proteinPropertiesState = useTypedSelector(({ proteinProperties }) => proteinProperties);
  const dictionariesState = useTypedSelector(({ dictionaries }) => dictionaries);
  const generateXLSXfileState = useTypedSelector(({ generateXLSXfile }) => generateXLSXfile);
  const { setProteinPropertiesTableColumns, downloadXLSXFile } = useActions();

  const isDownloadingXLSXfile = generateXLSXfileState?.loading || false;

  const tableDataset = React.useMemo<ProteinPropertiesElement[]>(
    () => proteinPropertiesState?.data || [],
    [proteinPropertiesState?.data],
  );

  React.useEffect(() => {
    document.title = RoutingLabels.PROTEIN_PROPERTIES;
  }, []);

  const handleDownloadXLSXfile = () => {
    if (generateXLSXfileState?.loading) return; // 🔥 do not fetch API call when active 🔥
    const tableColumns = proteinPropertiesState?.columns.table || [];
    const data = proteinPropertiesState?.data || [];
    const name = RoutingLabels.PROTEIN_PROPERTIES;
    downloadXLSXFile({
      tableColumns,
      data,
      name,
    });
  };

  const customizeTableRow: CustomizeTableRow[] = [
    {
      id: AvailableProteinColumnIDs.GENE,
      customStyle: 'justify-content: flex-start; padding-left: 5px',
      pageLink: {
        active: true,
        availableLinks: proteinPropertiesState?.geneLinks || new Map<string, string>(),
        mainURL: RoutingURLs.PROTEIN_PROPERTIES,
      },
    },
    {
      id: AvailableProteinColumnIDs.PERCENTAGE_GENE_HOMOLOGY,
      dynamicCustomStyle: getCellCustomStyle,
    },
    {
      id: AvailableProteinColumnIDs.AMINOACID_PERCENTAGE_HOMOLOGY,
      dynamicCustomStyle: getCellCustomStyle,
    },
  ];

  const dictColumnWidth = {
    [AvailableProteinColumnIDs.GENE]: 60,
    [AvailableProteinColumnIDs.ABBREVIATION]: 170,
  };

  return (
    <>
      {proteinPropertiesState?.isDataPrepared && (
        <PageTemplate>
          <PreviousPageBtn to="/" />
          <MainHeader>{RoutingLabels.PROTEIN_PROPERTIES}</MainHeader>
          <DescriptionParagraph disableReadMoreBtn>
            Choose an amino acid sequence of interest from the list below to see the comparison and
            the secondary structure of canine and human proteins:
          </DescriptionParagraph>
          {dictionariesState?.data.proteinProperties.columnNames && (
            <CustomTable
              data={tableDataset}
              columnParams={{
                selected: proteinPropertiesState.columns.selected,
                table: proteinPropertiesState.columns.table,
                setTableColumns: setProteinPropertiesTableColumns,
                dictColumnNames: dictionariesState?.data.proteinProperties.columnNames,
                dictColumnWidth,
              }}
              generateXLSX={{
                handleDownload: handleDownloadXLSXfile,
                isDownloading: isDownloadingXLSXfile,
              }}
              customizeTableRow={customizeTableRow}
            />
          )}
        </PageTemplate>
      )}
    </>
  );
};
