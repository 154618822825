/* eslint-disable no-param-reassign */
import produce from 'immer';
import { ActionType } from '../../action-types';
import { Action } from '../../actions';

interface GenerateXLSXfileState {
  loading: boolean;
  error: string | null;
}

export const initialState: GenerateXLSXfileState = {
  loading: false,
  error: null,
};

const reducer = produce((state: GenerateXLSXfileState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.DOWNLOAD_XLSX_FILE:
      state.loading = true;
      return state;
    case ActionType.DOWNLOAD_XLSX_FILE_COMPLETE:
      state.error = null;
      state.loading = false;
      return state;
    case ActionType.DOWNLOAD_XLSX_FILE_ERROR:
      state.loading = false;
      state.error = action.payload;
      return state;
    default:
      return state;
  }
});

export default reducer;
