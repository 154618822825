/* eslint-disable no-param-reassign */
import produce from 'immer';
import { Column } from 'react-table';
import { ActionType } from '../../action-types';
import { Action } from '../../actions';

import { ProteinPropertiesElement } from '../../proteinProperties';

export interface ProteinPropertiesState {
  data: ProteinPropertiesElement[];
  columns: {
    selected: string[];
    table: Column<ProteinPropertiesElement>[];
  };
  isDataPrepared: boolean;
  geneLinks: Map<string, string>;
}

export const initialState: ProteinPropertiesState = {
  data: [],
  columns: {
    selected: [],
    table: [],
  },
  isDataPrepared: false,
  geneLinks: new Map(),
};

const reducer = produce((state: ProteinPropertiesState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_PROTEIN_PROPERTIES_DATA:
      state.data = action.payload;
      state.isDataPrepared = true;
      return state;
    case ActionType.SET_PROTEIN_PROPERTIES_SELECTED_COLUMNS:
      state.columns.selected = action.payload;
      return state;
    case ActionType.SET_PROTEIN_PROPERTIES_TABLE_COLUMNS:
      state.columns.table = action.payload;
      return state;
    case ActionType.SET_PROTEIN_PROPERTIES_GENE_LINKS:
      state.geneLinks = action.payload;
      return state;
    default:
      return state;
  }
});

export default reducer;
