/* eslint-disable object-curly-newline */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import reportWebVitals from './reportWebVitals';
import { store } from './state';
import { theme } from './components/theme/theme';
import GlobalStyle from './components/theme/GlobalStyle';
import {
  NotFound404,
  WelcomePage,
  CanisSNPfinder,
  TRNAproperties,
  TRNApropertiesSingleLink,
  MRNAproperties,
  MRNApropertiesSingleLink,
  ProteinProperties,
  ProteinPropertiesSingleLink,
} from './components/pages';
import { RoutingURLs } from './routing';
import { WithLoadingDictionaries } from './components/HOC';
import { URL_PARAMS } from './state/tRNApropertiesSingleLink';
import 'react-toastify/dist/ReactToastify.css';
import { LoaderDNA } from './components/UI/atoms';
import ErrorBoundary from './components/templates/ErrorBoundary';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router basename={process.env.PUBLIC_URL}>
          <ErrorBoundary>
            <Switch>
              <Route exact path="/">
                <WithLoadingDictionaries Component={WelcomePage} />
              </Route>
              <Route exact path={`/${RoutingURLs.CANIS_SNP_FINDER}`}>
                <WithLoadingDictionaries Component={CanisSNPfinder} />
              </Route>
              <Route exact path={`/${RoutingURLs.TRNA_PROPERTIES}`}>
                <WithLoadingDictionaries Component={TRNAproperties} />
              </Route>
              <Route exact path={`/${RoutingURLs.MRNA_PROPERTIES}`}>
                <WithLoadingDictionaries Component={MRNAproperties} />
              </Route>
              <Route exact path={`/${RoutingURLs.PROTEIN_PROPERTIES}`}>
                <WithLoadingDictionaries Component={ProteinProperties} />
              </Route>
              <Route exact path={`/${RoutingURLs.TRNA_PROPERTIES}/:${URL_PARAMS.LINK_ID}`}>
                <WithLoadingDictionaries Component={TRNApropertiesSingleLink} />
              </Route>
              <Route exact path={`/${RoutingURLs.MRNA_PROPERTIES}/:${URL_PARAMS.LINK_ID}`}>
                <WithLoadingDictionaries Component={MRNApropertiesSingleLink} />
              </Route>
              <Route exact path={`/${RoutingURLs.PROTEIN_PROPERTIES}/:${URL_PARAMS.LINK_ID}`}>
                <WithLoadingDictionaries Component={ProteinPropertiesSingleLink} />
              </Route>
              <Route path="*" component={NotFound404} />
            </Switch>
          </ErrorBoundary>
        </Router>
        <LoaderDNA
          dotColor="hsl(216,53%,28%)"
          lineColor="hsla(0,0%,0%,0.55)"
          disableDotBoxShadow
          coverEverything
        />
      </ThemeProvider>
    </Provider>
    <ToastContainer
      position="top-center"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
