import * as React from 'react';
import { Footer } from '../../templates';
import { ContentFlexWrapper, ErrorHeader, ErrorSection } from '../../UI/atoms';
import { useActions } from '../../../hooks/use-actions';

export interface InternalServerError500Props {}
export const InternalServerError500: React.FC<InternalServerError500Props> = () => {
  const { disableDnaMainLoader } = useActions();
  React.useEffect(() => {
    disableDnaMainLoader();
  }, []);
  return (
    <ErrorSection>
      <ContentFlexWrapper flexGrow color="white" flexCenter customFontFamily darkBackground>
        <ErrorHeader>500 - Internal Server Error</ErrorHeader>
      </ContentFlexWrapper>
      <Footer />
    </ErrorSection>
  );
};
