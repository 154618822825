/* eslint-disable no-param-reassign */
import produce from 'immer';
import { ActionType } from '../../action-types';
import { Action } from '../../actions';

interface ErrorsState {
  isInternalServerError500: boolean;
}

export const initialState: ErrorsState = {
  isInternalServerError500: false,
};

const reducer = produce((state: ErrorsState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_INTERNAL_SERVER_ERROR_500:
      state.isInternalServerError500 = true;
      return state;
    default:
      return state;
  }
});

export default reducer;
