import styled from 'styled-components';

export const ErrorMessage = styled.p`
  color: red;
  padding-left: 5px;
  font-size: 1.3rem;
  flex-basis: 100%;

  @media (min-width: ${(props) => props.theme.screenWidthSize.tablet.small}) {
    flex-basis: auto;
  }
`;
