/* eslint-disable object-curly-newline */
import * as React from 'react';
import universityBanner from '../../../img/uni-banner.png';
import {
  AddressInfo,
  CreatedByLink,
  FancyText,
  FooterWrapper,
  LogotypeBanner,
  TextInformation,
  CopyrightWrapper,
  UniversityInfoWrapper,
  HowToCiteWrapper,
  HowToCiteHeader,
  GrantInfo,
} from './style';

export interface FooterProps {}
export const Footer: React.FC<FooterProps> = () => (
  <FooterWrapper>
    <UniversityInfoWrapper>
      <LogotypeBanner src={universityBanner} alt="University Of Life Sciences in Lublin" />
      <HowToCiteWrapper>
        <GrantInfo>
          This website was funded by grant number 2019/35/B/NZ5/00775 from the National Science
          Centre in Poland.
        </GrantInfo>
        <HowToCiteHeader>How to cite</HowToCiteHeader>
        <TextInformation>
          Kowal K, Tkaczyk-Wlizło A, Jusiak M, Grzybowska-Szatkowska L, Ślaska B. Canis MitoSNP
          database: a functional tool useful for comparative analyses of human and canine
          mitochondrial genomes. J Appl Genet. 2023 Jun 23. doi: 10.1007/s13353-023-00764-w. Epub
          ahead of print. PMID: 37351774.
        </TextInformation>
      </HowToCiteWrapper>
    </UniversityInfoWrapper>
    <AddressInfo>
      University of Life Sciences in Lublin - 13 Akademicka Street, 20-950 Lublin
    </AddressInfo>
    <CopyrightWrapper>
      <TextInformation>
        Copyright &copy; University Of Life Sciences in Lublin. All Rights Reserved.
      </TextInformation>
      <CreatedByLink href="https://mjusiak.pl/">
        Created in association with <FancyText>mjusiak.pl</FancyText>
      </CreatedByLink>
    </CopyrightWrapper>
  </FooterWrapper>
);
