/* eslint-disable object-curly-newline */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import styled, { css, keyframes } from 'styled-components';
import { Column, useTable, useBlockLayout } from 'react-table';
import { AiOutlineDownload } from 'react-icons/ai';
import { FaSpinner } from 'react-icons/fa';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import { Button, StyledLink, Tooltip } from '../../atoms';
import { ColumnsMenu, ColumnsSelection } from '..';
import { AvailableCanisColumnIDs } from '../../../../state/canisSNPfinder';
import { AvailableTRNAColumnIDs } from '../../../../state/tRNAproperties';
import { getScrollbarWidth } from '../../../../utils';
import { AvailableTRNAsingleLinkIDs } from '../../../../state/tRNApropertiesSingleLink';
import { AvailableMRNAColumnIDs } from '../../../../state/mRNAproperties';
import { RoutingURLs } from '../../../../routing';
import { AvailableMRNAsingleLinkIDs } from '../../../../state/mRNApropertiesSingleLink';
import { AvailableProteinColumnIDs } from '../../../../state/proteinProperties';
import { AvailableProteinSingleLinkIDs } from '../../../../state/proteinPropertiesSingleLink';

const TABLE_BORDER_SIZE = 2;

const StyledTable = styled.table`
  border-left: ${TABLE_BORDER_SIZE}px solid ${({ theme }) => theme.colorsPallete.blue};
  border-right: ${TABLE_BORDER_SIZE}px solid ${({ theme }) => theme.colorsPallete.blue};
  border-bottom: ${TABLE_BORDER_SIZE}px solid ${({ theme }) => theme.colorsPallete.blue};
  box-shadow: 0 0 3px hsl(0, 0%, 65%);
  margin: 0 auto;
  border-spacing: 0; /* Removes the cell spacing via CSS */
  border-collapse: collapse; /* Optional - if you don't want to have double border where cells touch */
`;

interface StyledTableHeadProps {
  scrollBarSize: number;
}
const StyledTableHead = styled.thead<StyledTableHeadProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colorsPallete.blue};
  border: none;
  color: hsl(0, 0%, 100%);
  padding-right: ${({ scrollBarSize }) => scrollBarSize}px;
`;

interface StyledTRProps {
  setGrayBgc?: boolean;
}
const StyledTR = styled.tr<StyledTRProps>`
  ${({ setGrayBgc }) =>
    setGrayBgc &&
    css`
      background-color: hsl(0, 0%, 90%);
    `}
`;

interface StyledTHProps {
  customBorder?: string;
}
const StyledTH = styled.th<StyledTHProps>`
  font-weight: normal;
  padding: 5px 2px;
  display: flex !important; /* override react-table inline style */
  justify-content: center;
  align-items: center;

  ${({ customBorder }) =>
    customBorder &&
    css`
      ${customBorder}
    `}
`;

interface StyledTDProps {
  customStyle?: string | null;
  customBorder?: string;
  customColor?: CustomTextColor | CustomLegendColor | null;
}
const StyledTD = styled.td<StyledTDProps>`
  white-space: nowrap;
  display: flex !important; /* override react-table inline style */
  justify-content: center;
  align-items: center;

  ${({ customStyle }) =>
    customStyle &&
    css`
      ${customStyle}
    `}

  ${({ customBorder }) =>
    customBorder &&
    css`
      ${customBorder}
    `}

    ${({ customColor }) =>
    customColor &&
    css`
      color: ${({ theme }) => theme.textColors[customColor]};
    `}
`;

const elementWithTooltipStyle = css`
  display: -webkit-box !important; /* override react-table inline style */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  overflow: hidden;
  padding-left: 2px;
  text-align: center;
`;

const StyledTableBody = styled.tbody`
  background-color: hsl(0, 0%, 100%);

  ${StyledTR}:hover {
    background-color: #b1b1b1;
  }
`;

const Paragraph = styled.p``;

interface TableWrapperProps {
  $width: number;
}
const TableWrapper = styled.div<TableWrapperProps>`
  width: ${({ $width }) => $width + 2 * TABLE_BORDER_SIZE}px;
  overflow-x: auto;
`;

interface AdditionalContentProps {
  activeColumnsSelection?: boolean;
  setFlexEnd?: boolean;
}
const AdditionalContent = styled.div<AdditionalContentProps>`
  width: 100%;
  height: 100%;
  max-width: ${({ theme }) => theme.screenWidthSize.desktop.XGA};
  margin: 0 auto;
  position: relative;

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;

  ${({ activeColumnsSelection }) =>
    activeColumnsSelection &&
    css`
      padding-left: 35px;
    `}

  ${({ setFlexEnd }) =>
    setFlexEnd &&
    css`
      justify-content: flex-end;
    `}
`;

interface AdditionalWrapperProps {
  $width: number;
}
const AdditionalWrapper = styled.div<AdditionalWrapperProps>`
  width: ${({ $width }) => $width + 2 * TABLE_BORDER_SIZE}px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
`;

const DownloadLabel = styled.span``;

const spin = keyframes`
 from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const downloadIconsStyle = css`
  font-size: 2.2rem;
`;

const StyledDownloadIcon = styled(AiOutlineDownload)`
  ${downloadIconsStyle}
`;

const StyledSpinnerIcon = styled(FaSpinner)`
  ${downloadIconsStyle}
  animation: ${spin} infinite 2s linear;
`;

interface DescriptionWrapperProps {
  $width: number;
}

const DescriptionWrapper = styled.div<DescriptionWrapperProps>`
  width: ${({ $width }) => $width + 2 * TABLE_BORDER_SIZE}px;
`;

const AdditionalDescription = styled.p`
  max-width: ${({ theme }) => theme.screenWidthSize.desktop.XGA};
  margin: 0 auto;
  font-size: 1.8rem;
  font-style: italic;
`;

const CustomTableWrapper = styled.div`
  width: 100%;
  padding: 10px;
`;

export interface TableColumn {
  Header?: string;
  accessor?: string;
  width?: number;
}

// 🗒️: use Header & columns OR accessor
export interface GroupSelected {
  Header?: string;
  columnIDs?: string[];
  accessor?: string; // AvailableCanisColumnIDs | AvailableTRNAColumnIDs | AvailableTRNAsingleLinkIDs | Available...;
}

export interface DictColumnNames {
  [key: string]: string;
}

export interface ColumnParams {
  selected: string[];
  groupSelected?: GroupSelected[];
  table: Column<any>[];
  setTableColumns: Function;
  dictColumnNames: any; // CanisSNPFinderElement | TRNApropertiesElement | TRNApropertiesSingleLinkElement;
  dictColumnWidth?: {
    [key: string]: number;
  };
}

export type CustomTextColor = 'red' | 'brown' | 'orange';
export type CustomLegendColor = 'green' | 'yellow';

export interface CustomizeTableRow {
  id:
    | AvailableCanisColumnIDs
    | AvailableTRNAColumnIDs
    | AvailableTRNAsingleLinkIDs
    | AvailableMRNAColumnIDs
    | AvailableMRNAsingleLinkIDs
    | AvailableProteinColumnIDs
    | AvailableProteinSingleLinkIDs;
  customStyle?: string;
  pageLink?: {
    active: true;
    availableLinks: Map<string, string>;
    mainURL: RoutingURLs;
  };
  dynamicCustomStyle?: (value: number) => string;
  setValueTwoLinesWithTooltip?: boolean;
  getCustomTextColor?: (element: any) => CustomTextColor | CustomLegendColor | null;
}

export interface CustomTableProps {
  data: any[];
  columnParams: ColumnParams;
  clearWhenUnmount?: () => void;
  withDataLengthInfo?: boolean;
  generateXLSX?: {
    handleDownload: () => void;
    isDownloading: boolean;
  };
  columnsSelection?: ColumnsSelection;
  customizeTableRow?: CustomizeTableRow[];
  additionalTableDescription?: string;
}

export const CustomTable: React.FC<CustomTableProps> = ({
  data,
  clearWhenUnmount,
  withDataLengthInfo,
  generateXLSX,
  columnsSelection,
  columnParams,
  customizeTableRow,
  additionalTableDescription,
}: CustomTableProps) => {
  const { selected, groupSelected, setTableColumns, table, dictColumnNames, dictColumnWidth } =
    columnParams;

  const defaultColumn = React.useMemo(
    () => ({
      width: 105,
    }),
    [],
  );
  const scrollBarSize = React.useMemo(() => getScrollbarWidth(), []);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: table,
      data,
      defaultColumn,
    },
    useBlockLayout,
  );

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index];
      const setGrayBgc = index % 2 === 0;
      prepareRow(row);
      return (
        <StyledTR setGrayBgc={setGrayBgc} {...row.getRowProps({ style })}>
          {row.cells.map((cell) => {
            let view = cell.render('Cell');
            const customBorder = groupSelected && 'box-shadow: 1px 0 0 0 #ddd;';
            const customizeStyle = customizeTableRow?.find(
              (cmzParams) => cell.column.id === cmzParams.id,
            );
            let customStyle: string | null = null;
            let customColor: CustomTextColor | CustomLegendColor | null = null;
            if (customizeStyle) {
              if (customizeStyle.setValueTwoLinesWithTooltip) {
                // 🗒️: only for long text description!
                if (cell.value?.length > 17) {
                  view = (
                    <Tooltip customChildrenStyles={elementWithTooltipStyle} tooltip={cell.value}>
                      {cell.render('Cell')}
                    </Tooltip>
                  );
                }
              }
              if (customizeStyle.getCustomTextColor) {
                customColor = customizeStyle.getCustomTextColor(cell.row.values);
              }
              if (customizeStyle.customStyle) {
                customStyle = customizeStyle.customStyle;
              } else if (customizeStyle.dynamicCustomStyle) {
                const value = parseInt(cell.value.replace('%', ''), 10);
                customStyle = customizeStyle.dynamicCustomStyle(value);
              }
              if (customizeStyle.pageLink?.active) {
                const to = customizeStyle.pageLink.availableLinks.get(cell.value);
                if (to) {
                  view = (
                    <StyledLink
                      to={`${customizeStyle.pageLink.mainURL}/${to}`}
                      label={cell.value}
                      defaultLinkView
                    />
                  );
                }
              }
            }
            return (
              <>
                <StyledTD
                  customBorder={customBorder}
                  customStyle={customStyle}
                  customColor={customColor}
                  {...cell.getCellProps()}
                >
                  {view}
                </StyledTD>
              </>
            );
          })}
        </StyledTR>
      );
    },
    [prepareRow, rows],
  );

  // 🚀 used as ComponentWillUnmount 🚀
  React.useLayoutEffect(
    () => () => {
      if (clearWhenUnmount) clearWhenUnmount();
    },
    [],
  );

  React.useEffect(() => {
    let tableColumns: TableColumn[] = [];
    if (groupSelected) {
      tableColumns = groupSelected.map((params) => {
        const { accessor, Header, columnIDs } = params;
        if (accessor) {
          return {
            Header: dictColumnNames[accessor],
            accessor,
          };
        }
        return {
          Header,
          columns: columnIDs?.map((id) => {
            let retValue: TableColumn = {
              Header: dictColumnNames[id],
              accessor: id,
            };
            if (dictColumnWidth && dictColumnWidth[id]) {
              retValue = { ...retValue, width: dictColumnWidth[id] };
            }
            return retValue;
          }),
        };
      });
    } else {
      tableColumns = selected.map((columnID) => {
        let retValue: TableColumn = {
          Header: dictColumnNames[columnID],
          accessor: columnID,
        };
        if (dictColumnWidth && dictColumnWidth[columnID]) {
          retValue = { ...retValue, width: dictColumnWidth[columnID] };
        }
        return retValue;
      });
    }
    setTableColumns(tableColumns);
  }, [columnParams.selected, columnParams.groupSelected]);

  const downloadButtonIcon = generateXLSX?.isDownloading ? (
    <StyledSpinnerIcon />
  ) : (
    <StyledDownloadIcon />
  );

  // If in AdditionalWrapper there is only download button without info how many records - let it be at the end
  const setAdditionalWrapperFlexEnd = !!(!withDataLengthInfo && generateXLSX);

  return (
    <CustomTableWrapper>
      <AutoSizer disableHeight>
        {({ width }) => (
          <>
            {(withDataLengthInfo || generateXLSX) && (
              <AdditionalWrapper $width={width}>
                <AdditionalContent
                  setFlexEnd={setAdditionalWrapperFlexEnd}
                  activeColumnsSelection={columnsSelection?.active}
                >
                  {columnsSelection && columnsSelection.active && (
                    <ColumnsMenu columnsSelection={columnsSelection} columnParams={columnParams} />
                  )}
                  {withDataLengthInfo && (
                    <Paragraph>
                      Found {data.length} record{data.length === 0 || data.length > 1 ? 's' : ''}.
                    </Paragraph>
                  )}
                  {generateXLSX?.handleDownload && (
                    <Button
                      padding="0.2rem 0.4rem"
                      margin="0"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      onClick={generateXLSX.handleDownload}
                    >
                      {downloadButtonIcon}
                      <DownloadLabel>Download XLSX file</DownloadLabel>
                    </Button>
                  )}
                </AdditionalContent>
              </AdditionalWrapper>
            )}
            <TableWrapper $width={width}>
              <StyledTable {...getTableProps()}>
                <StyledTableHead scrollBarSize={scrollBarSize}>
                  {headerGroups.map((headerGroup) => (
                    <StyledTR {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        const customBorder =
                          groupSelected && `box-shadow: 0 1px 0 0 #bfbfbf, 1px 0 0 0 #bfbfbf;`;
                        return (
                          <StyledTH customBorder={customBorder} {...column.getHeaderProps()}>
                            {column.render('Header')}
                          </StyledTH>
                        );
                      })}
                    </StyledTR>
                  ))}
                </StyledTableHead>
                <StyledTableBody {...getTableBodyProps()}>
                  <FixedSizeList height={500} itemCount={rows.length} itemSize={50} width="100%">
                    {RenderRow}
                  </FixedSizeList>
                  {rows.length === 0 && (
                    <StyledTR>
                      {Array.from(Array(table.length).keys()).map((val, index) => {
                        const label = index === 0 ? 'No record found.' : '';
                        return <StyledTD key={val}>{label}</StyledTD>;
                      })}
                    </StyledTR>
                  )}
                </StyledTableBody>
              </StyledTable>
            </TableWrapper>
            {!!additionalTableDescription && (
              <DescriptionWrapper $width={width}>
                <AdditionalDescription>{additionalTableDescription}</AdditionalDescription>
              </DescriptionWrapper>
            )}
          </>
        )}
      </AutoSizer>
    </CustomTableWrapper>
  );
};
