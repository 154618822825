export enum AvailableTRNAColumnIDs {
  GENE = 'gene',
  ABBREVIATION = 'abbreviation',
  DOG_START = 'dogStart',
  DOG_END = 'dogEnd',
  HUMAN_START = 'humanStart',
  HUMAN_END = 'humanEnd',
  LENGTH_DOG = 'lengthDog',
  LENGTH_HUMAN = 'lengthHuman',
  TRANSITIONS = 'transitions',
  TRANSVERATIONS = 'transverations',
  GAPS = 'gaps',
  IDENTICAL_POSITIONS = 'identicalPositions',
  SUM_OF_POSITION_AND_GAPS = 'sumOfPositionAndGaps',
  PERCENTAGE_HOMOLOGY = 'percentageHomology',
}

export interface TRNApropertiesElement {
  [AvailableTRNAColumnIDs.GENE]: string;
  [AvailableTRNAColumnIDs.ABBREVIATION]: string;
  [AvailableTRNAColumnIDs.DOG_START]: string;
  [AvailableTRNAColumnIDs.DOG_END]: string;
  [AvailableTRNAColumnIDs.HUMAN_START]: string;
  [AvailableTRNAColumnIDs.HUMAN_END]: string;
  [AvailableTRNAColumnIDs.LENGTH_DOG]: string;
  [AvailableTRNAColumnIDs.LENGTH_HUMAN]: string;
  [AvailableTRNAColumnIDs.TRANSITIONS]: string;
  [AvailableTRNAColumnIDs.TRANSVERATIONS]: string;
  [AvailableTRNAColumnIDs.GAPS]: string;
  [AvailableTRNAColumnIDs.IDENTICAL_POSITIONS]: string;
  [AvailableTRNAColumnIDs.SUM_OF_POSITION_AND_GAPS]: string;
  [AvailableTRNAColumnIDs.PERCENTAGE_HOMOLOGY]: string;
}
