import * as React from 'react';
import { DescriptionParagraph, CustomTable, CustomizeTableRow } from '../../UI/molecules';
import { PageTemplate } from '../../templates';
import { MainHeader, PreviousPageBtn } from '../../UI/atoms';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { RoutingLabels, RoutingURLs } from '../../../routing';
import { useActions } from '../../../hooks/use-actions';
import { AvailableTRNAColumnIDs, TRNApropertiesElement } from '../../../state/tRNAproperties';
import { getCellCustomStyle } from '../../../utils';

export interface TRNApropertiesProps {}
export const TRNAproperties: React.FC<TRNApropertiesProps> = () => {
  const dictionariesState = useTypedSelector(({ dictionaries }) => dictionaries);
  const generateXLSXfileState = useTypedSelector(({ generateXLSXfile }) => generateXLSXfile);
  const tRNApropertiesState = useTypedSelector(({ tRNAproperties }) => tRNAproperties);
  const { setTRNApropertiesTableColumns, downloadXLSXFile } = useActions();

  const isDownloadingXLSXfile = generateXLSXfileState?.loading || false;

  const tableDataset = React.useMemo<TRNApropertiesElement[]>(
    () => tRNApropertiesState?.data || [],
    [tRNApropertiesState?.data],
  );

  React.useEffect(() => {
    document.title = RoutingLabels.TRNA_PROPERTIES;
  }, []);

  const handleDownloadXLSXfile = () => {
    if (generateXLSXfileState?.loading) return; // 🔥 do not fetch API call when active 🔥
    const tableColumns = tRNApropertiesState?.columns.table || [];
    const data = tRNApropertiesState?.data || [];
    const name = RoutingLabels.TRNA_PROPERTIES;
    downloadXLSXFile({
      tableColumns,
      data,
      name,
    });
  };

  const customizeTableRow: CustomizeTableRow[] = [
    {
      id: AvailableTRNAColumnIDs.GENE,
      customStyle: 'justify-content: flex-start; padding-left: 5px',
      pageLink: {
        active: true,
        availableLinks: tRNApropertiesState?.geneLinks || new Map<string, string>(),
        mainURL: RoutingURLs.TRNA_PROPERTIES,
      },
    },
    {
      id: AvailableTRNAColumnIDs.PERCENTAGE_HOMOLOGY,
      dynamicCustomStyle: getCellCustomStyle,
    },
  ];

  return (
    <>
      {tRNApropertiesState?.isDataPrepared && (
        <PageTemplate>
          <PreviousPageBtn to="/" />
          <MainHeader>{RoutingLabels.TRNA_PROPERTIES}</MainHeader>
          <DescriptionParagraph disableReadMoreBtn>
            Choose a tRNA gene of interest from the list below to see the comparison and the
            secondary structure of canine and human mt-tRNA genes:
          </DescriptionParagraph>
          {dictionariesState?.data.tRNAproperties.columnNames && (
            <CustomTable
              data={tableDataset}
              columnParams={{
                selected: tRNApropertiesState.columns.selected,
                table: tRNApropertiesState.columns.table,
                setTableColumns: setTRNApropertiesTableColumns,
                dictColumnNames: dictionariesState?.data.tRNAproperties.columnNames,
              }}
              generateXLSX={{
                handleDownload: handleDownloadXLSXfile,
                isDownloading: isDownloadingXLSXfile,
              }}
              customizeTableRow={customizeTableRow}
              additionalTableDescription="* Genes on the complement strand"
            />
          )}
        </PageTemplate>
      )}
    </>
  );
};
