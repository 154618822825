/* eslint-disable object-curly-newline */
import * as React from 'react';
import { CanisSNPFinderForm } from '../../UI/organisms';
import { DescriptionParagraph, CustomTable, CustomizeTableRow } from '../../UI/molecules';
import { PageTemplate } from '../../templates';
import { MainHeader, PreviousPageBtn } from '../../UI/atoms';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import {
  AvailableCanisColumnIDs,
  CanisSNPFinderElement,
  customWidth,
} from '../../../state/canisSNPfinder';
import { useActions } from '../../../hooks/use-actions';
import { RoutingLabels } from '../../../routing';

export interface CanisSNPfinderProps {}
export const CanisSNPfinder: React.FC<CanisSNPfinderProps> = () => {
  const canisSNPFinderState = useTypedSelector(({ canisSNPFinder }) => canisSNPFinder);
  const generateXLSXfileState = useTypedSelector(({ generateXLSXfile }) => generateXLSXfile);
  const dictionariesState = useTypedSelector(({ dictionaries }) => dictionaries);
  const {
    clearCanisSNPFinderData,
    downloadXLSXFile,
    setCanisSNPFinderTableColumns,
    setCanisSNPFinderSelectedColumns,
    disableDnaAPILoader,
    enableDnaAPILoader,
  } = useActions();

  React.useEffect(() => {
    document.title = RoutingLabels.CANIS_SNP_FINDER;
  }, []);

  React.useEffect(() => {
    if (canisSNPFinderState?.loading) enableDnaAPILoader();
    else disableDnaAPILoader();
  }, [canisSNPFinderState?.loading]);

  const tableDataset = React.useMemo<CanisSNPFinderElement[]>(
    () => canisSNPFinderState?.data || [],
    [canisSNPFinderState?.data],
  );

  const handleDownloadXLSXfile = () => {
    if (generateXLSXfileState?.loading) return; // 🔥 do not fetch API call when active 🔥
    const tableColumns = canisSNPFinderState?.columns.table || [];
    const data = canisSNPFinderState?.data || [];
    const name = RoutingLabels.CANIS_SNP_FINDER;
    downloadXLSXFile({
      tableColumns,
      data,
      name,
    });
  };

  const isDownloadingXLSXfile = generateXLSXfileState?.loading || false;

  const customizeTableRow: CustomizeTableRow[] = [
    {
      id: AvailableCanisColumnIDs.SOPMA,
      setValueTwoLinesWithTooltip: true,
    },
    {
      id: AvailableCanisColumnIDs.SECOND_STRUCTURE_FEP,
      setValueTwoLinesWithTooltip: true,
    },
    {
      id: AvailableCanisColumnIDs.SECOND_STRUCTURE_TEP,
      setValueTwoLinesWithTooltip: true,
    },
  ];

  const dictColumnWidth = {
    [AvailableCanisColumnIDs.ID]: customWidth.SMALL,
    [AvailableCanisColumnIDs.GENOME_POSITION]: customWidth.SMALL,
    [AvailableCanisColumnIDs.DOG_MTDNA_5_3_STRAND]: customWidth.MEDIUM,
    [AvailableCanisColumnIDs.DOG_MTDNA_3_5_STRAND]: customWidth.MEDIUM,
    [AvailableCanisColumnIDs.TYPE]: customWidth.X_MEDIUM,
    [AvailableCanisColumnIDs.GENE]: customWidth.X_MEDIUM,
    [AvailableCanisColumnIDs.GENE_POSITION]: customWidth.SMALL,
    [AvailableCanisColumnIDs.CODON]: customWidth.SMALL,
    [AvailableCanisColumnIDs.AMINOACID_POSITION]: customWidth.XL_SMALL,
    [AvailableCanisColumnIDs.AMINOACID_1_LETTER]: customWidth.XL_SMALL,
    [AvailableCanisColumnIDs.AMINOACID_3_LETTER]: customWidth.XL_SMALL,
    [AvailableCanisColumnIDs.SOPMA]: customWidth.XS_LARGE,
    [AvailableCanisColumnIDs.TMHMM]: customWidth.SMALL,
    [AvailableCanisColumnIDs.CONSERVATION_GRADE]: customWidth.XS_LARGE,
    [AvailableCanisColumnIDs.BURRIED_OR_EXPOSED_RESIDUE]: customWidth.LARGE,
    [AvailableCanisColumnIDs.FUNCTION]: customWidth.XL_LARGE,
    [AvailableCanisColumnIDs.TRNA_SCAN]: customWidth.X_MEDIUM,
    [AvailableCanisColumnIDs.SECOND_STRUCTURE_FEP]: customWidth.XL_MEDIUM,
    [AvailableCanisColumnIDs.SECOND_STRUCTURE_TEP]: customWidth.XL_MEDIUM,
    [AvailableCanisColumnIDs.HUMAN_MTDNA_POSITION]: customWidth.XS_SMALL,
    [AvailableCanisColumnIDs.HUMAN_MTDNA_REF_SEQ]: customWidth.XS_SMALL,
    [AvailableCanisColumnIDs.IDENTICAL_DIFFERENT]: customWidth.XS_SMALL,
    [AvailableCanisColumnIDs.HUMAN_GENE]: customWidth.X_LARGE,
  };

  return (
    <PageTemplate>
      <PreviousPageBtn to="/" />
      <MainHeader>{RoutingLabels.CANIS_SNP_FINDER}</MainHeader>
      <DescriptionParagraph>
        The tool facilitates finding the homological position in human and canine mitochondrial DNA
        and determination of their identity or differences. The human mtDNA genome is 16 569 bp
        long, whereas the canine genome is 16 727 bp long. Some genes represent a high homology
        rate, thus the positions in these genes correspond to each other. In other cases, identical
        regions can be spread through the gene and separated by different nucleotides that may have
        been changed in the evolutionary process.
      </DescriptionParagraph>
      <CanisSNPFinderForm />
      {canisSNPFinderState?.isDataPrepared && dictionariesState?.data.canisSNPFinder.columnNames && (
        <CustomTable
          data={tableDataset}
          columnParams={{
            selected: canisSNPFinderState.columns.selected,
            table: canisSNPFinderState.columns.table,
            setTableColumns: setCanisSNPFinderTableColumns,
            dictColumnNames: dictionariesState?.data.canisSNPFinder.columnNames,
            dictColumnWidth,
          }}
          clearWhenUnmount={clearCanisSNPFinderData}
          withDataLengthInfo
          generateXLSX={{
            handleDownload: handleDownloadXLSXfile,
            isDownloading: isDownloadingXLSXfile,
          }}
          columnsSelection={{
            active: true,
            availableColumnIDs: Object.values(AvailableCanisColumnIDs),
            basic: canisSNPFinderState.columns.basic,
            setSelected: setCanisSNPFinderSelectedColumns,
          }}
          customizeTableRow={customizeTableRow}
        />
      )}
    </PageTemplate>
  );
};
