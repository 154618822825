import styled from 'styled-components';

export const MoleculeWrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.screenWidthSize.desktop.XGA}) {
    display: flex;
  }

  @media (min-width: ${({ theme }) => theme.screenWidthSize.desktop.WXGA}) {
    flex-direction: column;
    flex-basis: 95%;
  }
`;
