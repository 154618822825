export enum FormIDs {
  HUMAN_TO_CANINE = 'human-canine',
  CANINE_TO_HUMAN = 'canine-human',
  SNP_IN_GENOME = 'SNP-genome',
  SNP_IN_GENE = 'SNP-gene',
}

// canis-snp-finder
export type comparisonDirection = 'human-canine' | 'canine-human';
export type comparedPosition = 'SNP-genome' | 'SNP-gene';

export type AvailableFormNames =
  | 'comparisonDirection'
  | 'comparedPosition'
  | 'choosedGene'
  | 'positionNumber';

export enum FormNames {
  COMPARISON_DIRECTION = 'comparisonDirection',
  COMPARED_POSITION = 'comparedPosition',
  CHOOSED_GENE = 'choosedGene',
  POSITION_NUMBER = 'positionNumber',
  POSITION_NUMBER_SHOW_ALL = 'positionNumberShowAll',
}

export type AvailableFormIDs = comparisonDirection | comparedPosition;
