/* eslint-disable react/no-unused-state */
import * as React from 'react';
import { SomethingWentWrong } from '../../pages';

type ErrorBoundaryProps = {
  children: React.ReactNode;
};

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: any | null;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true,
    };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    // You can also log the error to an error reporting service
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return <SomethingWentWrong />;
    }
    return children;
  }
}

export default ErrorBoundary;
