import * as React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { useTypedSelector } from '../../../../hooks/use-typed-selector';

const ANIMATION_DELAY_TIME = 0.5;

const helix = keyframes` {
    0% { width: 5px; height: 5px; bottom: -5px; z-index: 10 }
   25% { width: 2px; height: 2px }
   50% { width: 5px; height: 5px; bottom: 100%; z-index: 20 }
   75% { width: 8px; height: 8px }
  100% { width: 5px; height: 5px; bottom: -5px }
}`;

const helixReversed = keyframes`{
    0% { width: 5px; height: 5px; bottom: 100%; z-index: 20 }
   25% { width: 8px; height: 8px }
   50% { width: 5px; height: 5px; bottom: -5px; z-index: 10 }
   75% { width: 2px; height: 2px }
  100% { width: 5px; height: 5px; bottom: 100% }
}`;

const helixBar = keyframes`{
    0% { height: 15px }
   25% { height:  8px }
   50% { height: 15px }
   75% { height:  8px }
  100% { height: 15px }
}`;

interface LineProps {
  animationDelay?: number;
  lineColor?: string;
}

const Line = styled.span<LineProps>`
  position: absolute;
  z-index: 25;
  align-self: center;
  width: 2px;
  height: 10px;
  background: ${(props) => props.lineColor || 'hsla(0, 0%, 100%, 0.15)'};
  animation: ${helixBar} 1.25s ease-in-out infinite;

  ${({ animationDelay }) =>
    animationDelay &&
    css`
      animation-delay: ${animationDelay}s;
    `}
`;

const LoaderAnimationWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 120px;
  height: 20px;
`;

interface DotProps {
  dotColor?: string;
  animationDelay?: number;
  disableDotBoxShadow?: boolean;
}

const Dot = styled.div<DotProps>`
  position: relative;
  flex: 1;
  display: flex;
  justify-content: center;
  align-content: center;
  margin: 0 5px;
  height: 20px;
  width: 2px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    display: block;
    width: 4px;
    height: 4px;
    background: ${(props) => props.dotColor || 'hsl(0, 0%, 100%)'};
    border-radius: 4px;
    box-shadow: 1px 1px 4px hsla(0, 0%, 0%, 0.15);
    animation: ${helix} 1.25s ease-in-out infinite;

    ${(props) =>
      props.disableDotBoxShadow &&
      css`
        box-shadow: none;
      `}
  }

  &::after {
    bottom: 100%;
    animation: ${helixReversed} 1.25s ease-in-out infinite;
  }

  ${({ animationDelay }) =>
    animationDelay &&
    css`
      &::before,
      &::after {
        animation-delay: ${animationDelay}s;
      }
    `}
`;

const Label = styled.p`
  padding-top: 5px;

  @media (min-width: ${(props) => props.theme.screenWidthSize.tablet.small}) {
    font-size: 1.8rem;
  }
`;

const toggleLabelDot = keyframes`{
  0% { opacity: 1 }
 25% { opacity: .8  }
 50% { opacity: 0.3 }
 75% { opacity: 0.8 }
 100% { opacity: 1 }
}`;

const LabelDot = styled.span`
  animation: ${toggleLabelDot} 1s linear infinite;

  &:nth-of-type(1) {
    animation-delay: 0.25s;
  }
  &:nth-of-type(2) {
    animation-delay: 0.5s;
  }
  &:nth-of-type(3) {
    animation-delay: 0.75s;
  }
`;

interface LoaderContainerProps {
  coverEverything?: boolean;
  isLoadingWhenCover?: boolean;
  margin?: string;
  disappearingAnimation?: boolean;
  activeTransparentBgc?: boolean;
}

const LoaderContainer = styled.div<LoaderContainerProps>`
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: opacity ${ANIMATION_DELAY_TIME}s;

  ${({ coverEverything }) =>
    coverEverything &&
    css`
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      background-color: hsl(0, 0%, 100%);
    `}

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

  ${({ disappearingAnimation }) =>
    disappearingAnimation &&
    css`
      opacity: 0;
    `}

  ${({ activeTransparentBgc }) =>
    activeTransparentBgc &&
    css`
      background-color: transparent;
    `}
`;

export interface LoaderDNAProps {
  dotColor?: string;
  lineColor?: string;
  disableDotBoxShadow?: boolean;
  coverEverything?: boolean;
  isLoadingWhenCover?: boolean;
  margin?: string;
  label?: string;
}

export const LoaderDNA: React.FC<LoaderDNAProps> = ({
  dotColor,
  lineColor,
  disableDotBoxShadow,
  coverEverything,
  isLoadingWhenCover,
  margin,
  label = 'Loading',
}: LoaderDNAProps) => {
  const dnaLoaderState = useTypedSelector(({ dnaLoader }) => dnaLoader);
  /**
   * ⭐ disappearingAnimation state:
   * ☘️ true: LoaderDNA disappears from UI by setting opacity to 0 (🚀 with animation 🚀)
   * ☘️ false: LoaderDNA appears in the app by removing previously set opacity to 0 (🚀 with animation 🚀)
   * ... false used when user wants to go from #404 to WelcomePage
   */
  const [disappearingAnimation, setDissapearingAnimation] = React.useState(false);
  /**
   * ⭐ disableLoader state:
   * ☘️ true: LoaderDNA is removed from DOM
   * ☘️ false: LoaderDNA is added to the DOM
   */
  const [disableLoader, setDisableLoader] = React.useState(false);
  /**
   * ⭐ activeTransparentBgc state:
   * ☘️ true: LoaderContainer is set background-color: transparent in order to animate (filter: blur) the elements underneath
   * ☘️ false: For LoaderContainer the background-color: transparent is removed (back to default white background)
   */
  const [activeTransparentBgc, setActiveTransparentBgc] = React.useState(false);

  React.useEffect(() => {
    if (!dnaLoaderState?.mainLoading) {
      setDissapearingAnimation(true);
      setTimeout(() => {
        setDisableLoader(true);
        setActiveTransparentBgc(true);
      }, ANIMATION_DELAY_TIME * 800);
    } else {
      setDisableLoader(false);
      setActiveTransparentBgc(false);
      setTimeout(() => {
        setDissapearingAnimation(false);
      }, 100);
    }
  }, [dnaLoaderState?.mainLoading]);

  React.useEffect(() => {
    if (!dnaLoaderState?.mainLoading) {
      if (dnaLoaderState?.apiLoading) {
        setDisableLoader(false);
        setTimeout(() => {
          setDissapearingAnimation(false);
        }, 100);
      } else {
        setDissapearingAnimation(true);
        setTimeout(() => {
          setDisableLoader(true);
        }, ANIMATION_DELAY_TIME * 800);
      }
    }
  }, [dnaLoaderState?.apiLoading]);

  if (disableLoader) return null;
  return (
    <LoaderContainer
      margin={margin}
      coverEverything={coverEverything}
      isLoadingWhenCover={isLoadingWhenCover}
      disappearingAnimation={disappearingAnimation}
      activeTransparentBgc={activeTransparentBgc}
    >
      <LoaderAnimationWrapper>
        <Dot disableDotBoxShadow={disableDotBoxShadow} dotColor={dotColor}>
          <Line lineColor={lineColor} />
        </Dot>
        <Dot disableDotBoxShadow={disableDotBoxShadow} dotColor={dotColor} animationDelay={0.05}>
          <Line lineColor={lineColor} animationDelay={0.05} />
        </Dot>
        <Dot disableDotBoxShadow={disableDotBoxShadow} dotColor={dotColor} animationDelay={0.1}>
          <Line lineColor={lineColor} animationDelay={0.1} />
        </Dot>
        <Dot disableDotBoxShadow={disableDotBoxShadow} dotColor={dotColor} animationDelay={0.15}>
          <Line lineColor={lineColor} animationDelay={0.15} />
        </Dot>
        <Dot disableDotBoxShadow={disableDotBoxShadow} dotColor={dotColor} animationDelay={0.2}>
          <Line lineColor={lineColor} animationDelay={0.2} />
        </Dot>
        <Dot disableDotBoxShadow={disableDotBoxShadow} dotColor={dotColor} animationDelay={0.25}>
          <Line lineColor={lineColor} animationDelay={0.25} />
        </Dot>
        <Dot disableDotBoxShadow={disableDotBoxShadow} dotColor={dotColor} animationDelay={0.3}>
          <Line lineColor={lineColor} animationDelay={0.3} />
        </Dot>
        <Dot disableDotBoxShadow={disableDotBoxShadow} dotColor={dotColor} animationDelay={0.35}>
          <Line lineColor={lineColor} animationDelay={0.35} />
        </Dot>
        <Dot disableDotBoxShadow={disableDotBoxShadow} dotColor={dotColor} animationDelay={0.4}>
          <Line lineColor={lineColor} animationDelay={0.4} />
        </Dot>
      </LoaderAnimationWrapper>
      <Label>
        {label}
        <LabelDot>.</LabelDot>
        <LabelDot>.</LabelDot>
        <LabelDot>.</LabelDot>
      </Label>
    </LoaderContainer>
  );
};
