import styled, { css } from 'styled-components';
import * as React from 'react';
import { Link } from 'react-router-dom';

const blueColor = 'hsl(227, 100%, 50%)';

interface SingleLinkProps {
  $color?: string;
  $hoverColor?: string;
  $defaultLinkView?: boolean;
}

const afterStyles = css`
  content: '';
  display: block;
  position: absolute;
  left: 0;
  width: 0;
  bottom: 0;
  right: 0;
  height: 2px;
`;

const SingleLink = styled(Link)<SingleLinkProps>`
  padding: 0 5px;
  text-decoration: none;
  color: ${({ $color }) => $color || 'black'};
  transition: color 0.2s;
  position: relative;

  @media (min-width: ${(props) => props.theme.screenWidthSize.desktop.XGA}) {
    &:hover {
      color: ${({ $hoverColor }) => $hoverColor || blueColor};
    }

    &:hover::after {
      width: 100%;
      transition: width 0.3s;
    }

    &::after {
      ${afterStyles}
      background-color: ${({ $hoverColor }) => $hoverColor || blueColor};
    }
  }

  ${({ $defaultLinkView, $hoverColor }) =>
    $defaultLinkView &&
    css`
      padding: 0;
      color: ${$hoverColor || blueColor};
      &::after {
        ${afterStyles}
        width: 100%;
        background-color: ${$hoverColor || blueColor};
      }
    `}
`;

export interface StyledLinkProps {
  to: string;
  label: string;
  color?: string;
  hoverColor?: string;
  defaultLinkView?: boolean;
}

export const StyledLink: React.FC<StyledLinkProps> = ({
  to,
  label,
  color,
  hoverColor,
  defaultLinkView,
}: StyledLinkProps) => (
  <SingleLink to={to} $color={color} $hoverColor={hoverColor} $defaultLinkView={defaultLinkView}>
    {label}
  </SingleLink>
);
