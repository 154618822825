import * as React from 'react';
import styled, { css } from 'styled-components';

type Display = 'block' | 'flex';

interface StyledButtonProps {
  margin?: string;
  display?: Display;
  alignItems?: string;
  justifyContent?: string;
  padding?: string;
}

const StyledButton = styled.button<StyledButtonProps>`
  font-size: 1.8rem;
  margin: 10px 0;
  padding: 0.5rem 6rem;
  color: ${({ theme }) => theme.colorsPallete.blue};
  font-family: ${({ theme }) => theme.fontFamily.header};
  border: 2px solid ${({ theme }) => theme.colorsPallete.blue};
  background-color: transparent;
  cursor: pointer;

  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.colorsPallete.blue};
    color: white;
  }

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

  ${({ display }) =>
    display &&
    css`
      display: ${display};
    `}

    ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems};
    `}

    ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `}

    ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
`;

export interface ButtonProps {
  type?: 'button' | 'submit' | 'reset';
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  margin?: string;
  display?: Display;
  justifyContent?: string;
  alignItems?: string;
  padding?: string;
}

export const Button: React.FC<ButtonProps> = ({
  type,
  children,
  onClick,
  margin,
  display,
  justifyContent,
  alignItems,
  padding,
}: ButtonProps) => (
  <StyledButton
    type={type}
    onClick={onClick}
    margin={margin}
    display={display}
    alignItems={alignItems}
    justifyContent={justifyContent}
    padding={padding}
  >
    {children}
  </StyledButton>
);
