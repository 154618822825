import styled from 'styled-components';

export interface MainHeaderProps {
  children: string;
}

export const MainHeader = styled.h1<MainHeaderProps>`
  font-family: ${(props) => props.theme.fontFamily.header};
  text-align: center;
  font-size: 3rem;
  padding-top: 20px;

  @media (min-width: ${(props) => props.theme.screenWidthSize.tablet.medium}) {
    font-size: 4rem;
  }

  @media (min-width: ${(props) => props.theme.screenWidthSize.desktop.XGA}) {
    font-size: 5rem;
  }
`;
