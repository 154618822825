export enum ActionType {
  FETCH_CANIS_SNP_FINDER = 'fetch_canis_snp_finder',
  FETCH_CANIS_SNP_FINDER_COMPLETE = 'fetch_canis_snp_finder_complete',
  FETCH_CANIS_SNP_FINDER_ERROR = 'fetch_canis_snp_finder_error',
  SET_CANIS_SNP_FINDER_SELECTED_COLUMNS = 'set_canis_snp_finder_selected_columns',
  SET_CANIS_SNP_FINDER_TABLE_COLUMNS = 'set_canis_snp_finder_table_columns',
  CLEAR_CANIS_SNP_FINDER_DATA = 'clear_canis_snp_finder_data',
  FETCH_DICTIONARIES = 'fetch_dictionaries',
  FETCH_DICTIONARIES_COMPLETE = 'fetch_dictionaries_complete',
  FETCH_DICTIONARIES_ERROR = 'fetch_dictionaries_error',
  SET_INTERNAL_SERVER_ERROR_500 = 'set_interval_server_error_500',
  DOWNLOAD_XLSX_FILE = 'download_xlsx_file',
  DOWNLOAD_XLSX_FILE_COMPLETE = 'download_xlsx_file_complete',
  DOWNLOAD_XLSX_FILE_ERROR = 'download_xlsx_file_error',
  SET_TRNA_PROPERTIES_DATA = 'set_tRNA_properties_data',
  SET_TRNA_PROPERTIES_SELECTED_COLUMNS = 'set_tRNA_properties_selected_columns',
  SET_TRNA_PROPERTIES_TABLE_COLUMNS = 'set_tRNA_properties_table_columns',
  SET_TRNA_PROPERTIES_GENE_LINKS = 'set_tRNA_properties_gene_links',
  FETCH_TRNA_PROPERTIES_SINGLE_LINK = 'fetch_tRNA_properties_single_link',
  FETCH_TRNA_PROPERTIES_SINGLE_LINK_COMPLETE = 'fetch_tRNA_properties_single_link_complete',
  FETCH_TRNA_PROPERTIES_SINGLE_LINK_ERROR = 'fetch_tRNA_properties_single_link_error',
  CLEAR_TRNA_PROPERTIES_SINGLE_LINK_DATA = 'clear_tRNA_properties_single_link_data',
  SET_TRNA_PROPERTIES_SINGLE_LINK_SELECTED_COLUMNS = 'set_tRNA_properties_single_link_selected_columns',
  SET_TRNA_PROPERTIES_SINGLE_LINK_GROUP_SELECTED_COLUMNS = 'set_tRNA_properties_single_link_group_selected_columns',
  SET_TRNA_PROPERTIES_SINGLE_LINK_TABLE_COLUMNS = 'set_tRNA_properties_single_link_table_columns',
  ENABLE_MAIN_LOADING = 'enable_main_loading',
  DISABLE_MAIN_LOADING = 'disable_main_loading',
  ENABLE_API_LOADING = 'enable_api_loading',
  DISABLE_API_LOADING = 'disable_api_loading',
  SET_MRNA_PROPERTIES_DATA = 'set_mRNA_properties_data',
  SET_MRNA_PROPERTIES_SELECTED_COLUMNS = 'set_mRNA_properties_selected_columns',
  SET_MRNA_PROPERTIES_TABLE_COLUMNS = 'set_mRNA_properties_table_columns',
  SET_MRNA_PROPERTIES_GENE_LINKS = 'set_mRNA_properties_gene_links',
  // mRNApropertiesSingleLinkReducer
  FETCH_MRNA_PROPERTIES_SINGLE_LINK = 'fetch_mRNA_single_link_properties',
  FETCH_MRNA_PROPERTIES_SINGLE_LINK_COMPLETE = 'fetch_mRNA_single_link_properties_complete',
  FETCH_MRNA_PROPERTIES_SINGLE_LINK_ERROR = 'fetch_mRNA_single_link_properties_error',
  CLEAR_MRNA_PROPERTIES_SINGLE_LINK_DATA = 'clear_mRNA_properties_single_link_data',
  SET_MRNA_PROPERTIES_SINGLE_LINK_SELECTED_COLUMNS = 'set_mRNA_properties_single_link_selected_columns',
  SET_MRNA_PROPERTIES_SINGLE_LINK_GROUP_SELECTED_COLUMNS = 'set_mRNA_properties_single_link_group_selected_columns',
  SET_MRNA_PROPERTIES_SINGLE_LINK_TABLE_COLUMNS = 'set_mRNA_properties_single_link_table_columns',
  // proteinPropertiesReducer
  SET_PROTEIN_PROPERTIES_DATA = 'set_protein_properties_data',
  SET_PROTEIN_PROPERTIES_SELECTED_COLUMNS = 'set_protein_properties_selected_columns',
  SET_PROTEIN_PROPERTIES_TABLE_COLUMNS = 'set_protein_properties_table_columns',
  SET_PROTEIN_PROPERTIES_GENE_LINKS = 'set_protein_properties_gene_links',
  // proteinPropertiesSingleLinkReducer
  FETCH_PROTEIN_PROPERTIES_SINGLE_LINK = 'fetch_protein_single_link_properties',
  FETCH_PROTEIN_PROPERTIES_SINGLE_LINK_COMPLETE = 'fetch_protein_single_link_properties_complete',
  FETCH_PROTEIN_PROPERTIES_SINGLE_LINK_ERROR = 'fetch_protein_single_link_properties_error',
  CLEAR_PROTEIN_PROPERTIES_SINGLE_LINK_DATA = 'clear_protein_properties_single_link_data',
  SET_PROTEIN_PROPERTIES_SINGLE_LINK_SELECTED_COLUMNS = 'set_protein_properties_single_link_selected_columns',
  SET_PROTEIN_PROPERTIES_SINGLE_LINK_TABLE_COLUMNS = 'set_protein_properties_single_link_table_columns',
}
