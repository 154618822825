import { SummaryItem } from './tRNApropertiesSingleLink';

export enum AvailableMRNAsingleLinkIDs {
  DOG_GENOMIC_POSITION = 'dogGenomicPosition',
  DOG_GENE_POSITION = 'dogGenePosition',
  DOG_CODON = 'dogCodon',
  DOG_AMINOACID = 'dogAminoacid',
  DOG_AA_POSITION_IN_PROTEIN = 'dogAaPositionInProtein',
  DOG_5_3_STRAND = 'dog53strand',
  HUMAN_GENOMIC_POSITION = 'humanGenomicPosition',
  HUMAN_GENE_POSITION = 'humanGenePosition',
  HUMAN_CODON = 'humanCodon',
  HUMAN_AMINOACID = 'humanAminoacid',
  HUMAN_AA_POSITION_IN_PROTEIN = 'humanAaPositionInProtein',
  HUMAN_5_3_STRAND = 'human53strand',
  SEQUENCE_HOMOLOGY = 'sequenceHomology',
}

export interface MRNApropertiesSingleLinkElement {
  [AvailableMRNAsingleLinkIDs.DOG_GENOMIC_POSITION]?: string;
  [AvailableMRNAsingleLinkIDs.DOG_GENE_POSITION]?: string;
  [AvailableMRNAsingleLinkIDs.DOG_CODON]?: string;
  [AvailableMRNAsingleLinkIDs.DOG_AMINOACID]?: string;
  [AvailableMRNAsingleLinkIDs.DOG_AA_POSITION_IN_PROTEIN]?: string;
  [AvailableMRNAsingleLinkIDs.DOG_5_3_STRAND]?: string;
  [AvailableMRNAsingleLinkIDs.HUMAN_GENOMIC_POSITION]?: string;
  [AvailableMRNAsingleLinkIDs.HUMAN_GENE_POSITION]?: string;
  [AvailableMRNAsingleLinkIDs.HUMAN_CODON]?: string;
  [AvailableMRNAsingleLinkIDs.HUMAN_AMINOACID]?: string;
  [AvailableMRNAsingleLinkIDs.HUMAN_AA_POSITION_IN_PROTEIN]?: string;
  [AvailableMRNAsingleLinkIDs.HUMAN_5_3_STRAND]?: string;
  [AvailableMRNAsingleLinkIDs.SEQUENCE_HOMOLOGY]?: string;
}

export interface FetchMRNApropertiesSingleLinkParams {
  dataset: MRNApropertiesSingleLinkElement[];
  summary: SummaryItem[];
}

export interface FetchMRNApropertiesSingleLinkCompletePayload {
  id: string;
  dataset: MRNApropertiesSingleLinkElement[];
  summary: SummaryItem[];
}
