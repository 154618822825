import * as React from 'react';
import styled from 'styled-components';
import { ErrorMessage, ContentFlexWrapper } from '..';

const StyledParagraph = styled.p``;

const Select = styled.select`
  background-color: white;
  border: 2px solid black;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.main};
  font-size: 1.5rem;
`;

const Option = styled.option``;

export interface StyledSelectProps {
  paragraphDescription: string;
  selectOptions: string[];
  value: string;
  id: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  error?: string | null;
}

export const StyledSelect: React.FC<StyledSelectProps> = ({
  paragraphDescription,
  selectOptions,
  value,
  id,
  onChange,
  error,
}: StyledSelectProps) => (
  <>
    <StyledParagraph>{paragraphDescription}</StyledParagraph>
    <ContentFlexWrapper
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="row"
      bottomMargin
    >
      <Select value={value} name={id} onChange={onChange}>
        {selectOptions.map((gene) => (
          <Option key={gene} value={gene}>
            {gene}
          </Option>
        ))}
      </Select>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </ContentFlexWrapper>
  </>
);
