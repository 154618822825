import * as React from 'react';
import styled from 'styled-components';
import { TiArrowForward } from 'react-icons/ti';
import { StyledLink, StyledLinkProps } from '../../atoms';

const StyledIcon = styled(TiArrowForward)`
  font-size: 22px;
`;

export const ListItem = styled.li`
  padding: 10px 0;
  text-decoration: none;
  display: flex;
  align-items: center;

  @media (min-width: ${(props) => props.theme.screenWidthSize.tablet.large}) {
    padding: 15px 0;
    font-size: 2.2rem;
  }
`;

export const StyledListItem: React.FC<StyledLinkProps> = ({ to, label }: StyledLinkProps) => (
  <ListItem>
    <StyledIcon />
    <StyledLink to={to} label={label} />
  </ListItem>
);
