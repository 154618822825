import * as React from 'react';
import styled, { css } from 'styled-components';
import { useTypedSelector } from '../../../../hooks/use-typed-selector';
import { usePrevious } from '../../../../hooks/usePrevious';

// 💔 Not used yet! 💔

interface ButtonProps {
  isActive: boolean;
}

const Button = styled.button<ButtonProps>`
  width: 50px;
  height: 26px;

  margin: 0 5px;
  border: 2px solid ${({ theme }) => theme.colorsPallete.blue};
  background-color: transparent;
  border-radius: 30px;
  position: relative;

  cursor: pointer;

  &::after {
    transition: transform 0.5s;
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(2px, -50%);
    display: block;
    height: 19px;
    width: 19px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colorsPallete.blue};

    ${({ isActive }) =>
      isActive &&
      css`
        transform: translate(25px, -50%);
      `}
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 15%;
`;

interface LabelProps {
  isActive: boolean;
  handleToggleDefaultLabel?: (isActive: boolean) => void;
  handleToggleSecondLabel?: (isActive: boolean) => void;
}

const Label = styled.span<LabelProps>`
  ${({ isActive }) =>
    isActive &&
    css`
      font-weight: bold;
      color: ${({ theme }) => theme.colorsPallete.blue}; ;
    `}

  ${({ handleToggleDefaultLabel, handleToggleSecondLabel }) =>
    (handleToggleDefaultLabel || handleToggleSecondLabel) &&
    css`
      cursor: pointer;
    `}
`;

export interface ToggleButtonProps {
  handleToggleButton?: (isActive: boolean) => void;
  withLabels?: {
    defaultLabel: string;
    secondLabel: string;
  };
  handleToggleDefaultLabel?: (isActive: boolean) => void;
  handleToggleSecondLabel?: (isActive: boolean) => void;
}
export const ToggleButton: React.FC<ToggleButtonProps> = ({
  handleToggleButton,
  withLabels,
  handleToggleDefaultLabel,
  handleToggleSecondLabel,
}: ToggleButtonProps) => {
  const [isActive, setIsActive] = React.useState(false);

  const handleOnClick = () => {
    if (handleToggleButton) handleToggleButton(isActive);
    setIsActive(!isActive);
  };

  const handleDefaultOnClick = () => {
    if (isActive) {
      if (handleToggleDefaultLabel) handleToggleDefaultLabel(isActive);
      setIsActive(false);
    }
  };

  const handleSecondOnClick = () => {
    if (!isActive) {
      if (handleToggleSecondLabel) handleToggleSecondLabel(isActive);
      setIsActive(true);
    }
  };

  // 🥑 -> Add-on to prevent the button from not updating isActive state after fetching canisSNPFinder.data
  const canisSNPFinderState = useTypedSelector(({ canisSNPFinder }) => canisSNPFinder);
  const isLoadingCanisSNPFinder = canisSNPFinderState?.loading;
  const prevIsLoadingCanisSNPFinder: boolean = usePrevious(canisSNPFinderState?.loading);
  React.useEffect(() => {
    if (
      isLoadingCanisSNPFinder === false &&
      prevIsLoadingCanisSNPFinder === true &&
      isActive === true
    ) {
      // 🧨 Fix error -> after fetching data come back to default position!
      setIsActive(false);
    }
  }, [isLoadingCanisSNPFinder, prevIsLoadingCanisSNPFinder]);

  if (withLabels) {
    return (
      <Wrapper>
        <Label
          isActive={!isActive}
          onClick={handleDefaultOnClick}
          handleToggleDefaultLabel={handleToggleDefaultLabel}
        >
          {withLabels.defaultLabel}
        </Label>
        <Button isActive={isActive} onClick={handleOnClick} />
        <Label
          isActive={isActive}
          onClick={handleSecondOnClick}
          handleToggleSecondLabel={handleToggleSecondLabel}
        >
          {withLabels.secondLabel}
        </Label>
      </Wrapper>
    );
  }

  return <Button isActive={isActive} onClick={handleOnClick} />;
};
