/* eslint-disable object-curly-newline */
import * as React from 'react';
import { FaRegSadCry } from 'react-icons/fa';
import { Footer } from '../../templates';
import { ContentFlexWrapper, ErrorHeader, ErrorSection } from '../../UI/atoms';
import { ISSUE_EMAIL } from '../../../global/types';
import {
  ButtonLabel,
  ButtonWrapper,
  Description,
  IssueLink,
  RefreshButton,
  StyledMailIcon,
  StyledRefreshIcon,
} from './style';

export interface SomethingWentWrongProps {}
export const SomethingWentWrong: React.FC<SomethingWentWrongProps> = () => {
  const handleRefresh = () => {
    window.location.reload();
  };
  return (
    <ErrorSection>
      <ContentFlexWrapper flexGrow color="white" flexCenter customFontFamily darkBackground>
        <ErrorHeader>
          Something went wrong <FaRegSadCry />
        </ErrorHeader>
        <Description>Please try again or report an issue</Description>
        <ButtonWrapper>
          <RefreshButton onClick={handleRefresh}>
            <StyledRefreshIcon />
            <ButtonLabel>Try again</ButtonLabel>
          </RefreshButton>
          <IssueLink
            href={`mailto:${ISSUE_EMAIL}?subject=Canis MitoSNP - report an issue&body=Describe the situation that caused the error...`}
          >
            <StyledMailIcon />
            <ButtonLabel>Report an issue</ButtonLabel>
          </IssueLink>
        </ButtonWrapper>
      </ContentFlexWrapper>
      <Footer />
    </ErrorSection>
  );
};
