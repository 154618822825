/* eslint-disable object-curly-newline */
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { CustomizeTableRow, CustomTable } from '../../UI/molecules';
import { PageTemplate } from '../../templates';
import { MainHeader, PreviousPageBtn, SingleLinkWrapper, SummaryList } from '../../UI/atoms';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { RoutingURLs } from '../../../routing';
import { useActions } from '../../../hooks/use-actions';
import { customWidth, UrlParams } from '../../../state/tRNApropertiesSingleLink';
import { getMapKeyByValue, getCustomTextColor } from '../../../utils';
import {
  AvailableMRNAsingleLinkIDs,
  MRNApropertiesSingleLinkElement,
} from '../../../state/mRNApropertiesSingleLink';

export interface MRNApropertiesSingleLinkProps {}
export const MRNApropertiesSingleLink: React.FC<MRNApropertiesSingleLinkProps> = () => {
  const mRNApropertiesState = useTypedSelector(({ mRNAproperties }) => mRNAproperties);
  const mRNApropertiesSingleLinkState = useTypedSelector(
    ({ mRNApropertiesSingleLink }) => mRNApropertiesSingleLink,
  );
  const dictionariesState = useTypedSelector(({ dictionaries }) => dictionaries);
  const generateXLSXfileState = useTypedSelector(({ generateXLSXfile }) => generateXLSXfile);
  const {
    fetchMRNApropertiesSingleLink,
    setMRNApropertiesSingleLinkTableColumns,
    downloadXLSXFile,
    enableDnaAPILoader,
    disableDnaAPILoader,
  } = useActions();
  const { linkID }: UrlParams = useParams();

  const currentID =
    mRNApropertiesState?.geneLinks && getMapKeyByValue(mRNApropertiesState?.geneLinks, linkID);

  React.useEffect(() => {
    if (linkID !== mRNApropertiesSingleLinkState?.id && currentID) {
      fetchMRNApropertiesSingleLink(currentID);
    }
    if (currentID) document.title = currentID;
  }, [mRNApropertiesSingleLinkState?.id, currentID]);

  React.useEffect(() => {
    if (mRNApropertiesSingleLinkState?.loading) enableDnaAPILoader();
    else disableDnaAPILoader();
  }, [mRNApropertiesSingleLinkState?.loading]);

  const tableDataset = React.useMemo<MRNApropertiesSingleLinkElement[]>(
    () => mRNApropertiesSingleLinkState?.data || [],
    [mRNApropertiesSingleLinkState?.data],
  );

  const handleDownloadXLSXfile = () => {
    if (generateXLSXfileState?.loading) return; // 🔥 do not fetch API call when active 🔥
    const tableColumns = mRNApropertiesSingleLinkState?.columns.table || [];
    const data = mRNApropertiesSingleLinkState?.data || [];
    const name = (currentID && mRNApropertiesState?.geneLinks.get(currentID)) || '';
    const mergedFirstRowHeaderCells = true;
    downloadXLSXFile({
      tableColumns,
      data,
      name,
      mergedFirstRowHeaderCells,
    });
  };

  const isDownloadingXLSXfile = generateXLSXfileState?.loading || false;

  const customizeTableRow: CustomizeTableRow[] = [
    {
      id: AvailableMRNAsingleLinkIDs.DOG_5_3_STRAND,
      getCustomTextColor,
    },
    {
      id: AvailableMRNAsingleLinkIDs.HUMAN_5_3_STRAND,
      getCustomTextColor,
    },
    {
      id: AvailableMRNAsingleLinkIDs.SEQUENCE_HOMOLOGY,
      getCustomTextColor,
    },
  ];

  const dictColumnWidth = {
    [AvailableMRNAsingleLinkIDs.DOG_GENOMIC_POSITION]: customWidth.SMALL,
    [AvailableMRNAsingleLinkIDs.DOG_GENE_POSITION]: customWidth.SMALL,
    [AvailableMRNAsingleLinkIDs.DOG_CODON]: customWidth.MEDIUM,
    [AvailableMRNAsingleLinkIDs.DOG_AMINOACID]: customWidth.MEDIUM,
    [AvailableMRNAsingleLinkIDs.DOG_AA_POSITION_IN_PROTEIN]: customWidth.LARGE,
    [AvailableMRNAsingleLinkIDs.DOG_5_3_STRAND]: customWidth.LARGE,
    [AvailableMRNAsingleLinkIDs.HUMAN_GENOMIC_POSITION]: customWidth.SMALL,
    [AvailableMRNAsingleLinkIDs.HUMAN_GENE_POSITION]: customWidth.SMALL,
    [AvailableMRNAsingleLinkIDs.HUMAN_CODON]: customWidth.MEDIUM,
    [AvailableMRNAsingleLinkIDs.HUMAN_AMINOACID]: customWidth.MEDIUM,
    [AvailableMRNAsingleLinkIDs.HUMAN_AA_POSITION_IN_PROTEIN]: customWidth.LARGE,
    [AvailableMRNAsingleLinkIDs.HUMAN_5_3_STRAND]: customWidth.LARGE,
  };

  return (
    <>
      <PageTemplate>
        <PreviousPageBtn to={`/${RoutingURLs.MRNA_PROPERTIES}`} />
        <MainHeader>{currentID || 'mRNA'}</MainHeader>
        {mRNApropertiesSingleLinkState?.loading ? null : (
          <SingleLinkWrapper centerContent>
            {mRNApropertiesSingleLinkState?.summary && (
              <SummaryList margin="0 auto" summary={mRNApropertiesSingleLinkState.summary} />
            )}
            {mRNApropertiesSingleLinkState?.columns.selected &&
              dictionariesState?.data.mRNApropertiesSingleLink.columnNames && (
                <CustomTable
                  data={tableDataset}
                  columnParams={{
                    selected: mRNApropertiesSingleLinkState?.columns.selected,
                    groupSelected: mRNApropertiesSingleLinkState.columns.groupSelected,
                    table: mRNApropertiesSingleLinkState.columns.table,
                    setTableColumns: setMRNApropertiesSingleLinkTableColumns,
                    dictColumnNames: dictionariesState?.data.mRNApropertiesSingleLink.columnNames,
                    dictColumnWidth,
                  }}
                  customizeTableRow={customizeTableRow}
                  generateXLSX={{
                    handleDownload: handleDownloadXLSXfile,
                    isDownloading: isDownloadingXLSXfile,
                  }}
                />
              )}
          </SingleLinkWrapper>
        )}
      </PageTemplate>
    </>
  );
};
