import styled, { css } from 'styled-components';
import { CustomLegendColor, CustomTextColor } from '../../molecules';

interface WrapperProps {
  margin?: string;
  width?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  min-width: 240px;
  max-width: 240px;
  border: 2px solid ${({ theme }) => theme.colorsPallete.blue};
  border-radius: 5px;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colorsPallete.blue};

  ${({ margin }) =>
    margin &&
    css`
      margin: ${margin};
    `}

  ${({ width }) =>
    width &&
    css`
      min-width: ${width};
      max-width: ${width};
    `}
`;

export const Header = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.header};
  text-transform: uppercase;
  text-align: center;
  margin: 0;
  padding: 0 0 2px;
  color: white;
  font-weight: normal;
  letter-spacing: 2px;
`;

export const List = styled.ul`
  background-color: white;
  list-style-type: none;
`;

export const ListItem = styled.li`
  display: flex;
  padding: 2px 10px;
  justify-content: space-between;

  &:nth-child(even) {
    background-color: #efefef;
  }
`;

interface ElementProps {
  boldText?: boolean;
  customColor?: CustomTextColor | CustomLegendColor;
}

export const Element = styled.span<ElementProps>`
  ${({ boldText }) =>
    boldText &&
    css`
      font-weight: bold;
    `}

  ${({ customColor }) =>
    customColor &&
    css`
      color: ${({ theme }) => theme.textColors[customColor]};
    `}
`;
