// bardzo podobne to AvailableTRNAColumnIDs ale sum of positions jest bez (and gaps)
export enum AvailableMRNAColumnIDs {
  LP = 'lp',
  GENE = 'gene',
  ABBREVIATION = 'abbreviation',
  DOG_START = 'dogStart',
  DOG_END = 'dogEnd',
  HUMAN_START = 'humanStart',
  HUMAN_END = 'humanEnd',
  LENGTH_DOG = 'lengthDog',
  LENGTH_HUMAN = 'lengthHuman',
  TRANSITIONS = 'transitions',
  TRANSVERATIONS = 'transverations',
  GAPS = 'gaps',
  IDENTICAL_POSITIONS = 'identicalPositions',
  SUM_OF_POSITIONS = 'sumOfPositions',
  PERCENTAGE_GENE_HOMOLOGY = 'percentageGeneHomology',
}

export interface MRNApropertiesElement {
  [AvailableMRNAColumnIDs.LP]: string;
  [AvailableMRNAColumnIDs.GENE]: string;
  [AvailableMRNAColumnIDs.ABBREVIATION]: string;
  [AvailableMRNAColumnIDs.DOG_START]: string;
  [AvailableMRNAColumnIDs.DOG_END]: string;
  [AvailableMRNAColumnIDs.HUMAN_START]: string;
  [AvailableMRNAColumnIDs.HUMAN_END]: string;
  [AvailableMRNAColumnIDs.LENGTH_DOG]: string;
  [AvailableMRNAColumnIDs.LENGTH_HUMAN]: string;
  [AvailableMRNAColumnIDs.TRANSITIONS]: string;
  [AvailableMRNAColumnIDs.TRANSVERATIONS]: string;
  [AvailableMRNAColumnIDs.GAPS]: string;
  [AvailableMRNAColumnIDs.IDENTICAL_POSITIONS]: string;
  [AvailableMRNAColumnIDs.SUM_OF_POSITIONS]: string;
  [AvailableMRNAColumnIDs.PERCENTAGE_GENE_HOMOLOGY]: string;
}

export enum URL_MRNA_PARAMS {
  LINK_ID = 'linkID',
}

export interface UrlParams {
  [URL_MRNA_PARAMS.LINK_ID]: string;
}
