import styled from 'styled-components';
import bgcImage from '../../../../img/err-bgc.jpg';

export const ErrorSection = styled.section`
  width: 100vw;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  background: url(${bgcImage}) no-repeat center;
  background-attachment: fixed;
  background-size: cover;
`;
