import * as React from 'react';
import { SUMMARY_ID } from '../../../../state/proteinPropertiesSingleLink';
import { SummaryItem } from '../../../../state/tRNApropertiesSingleLink';
import { getKeyValue } from '../../../../utils';
import { CustomLegendColor, CustomTextColor } from '../../molecules';
import { Element, Header, List, ListItem, Wrapper } from '../SummaryList/style';

type ColorsTitleMods = {
  [key in SUMMARY_ID]?: CustomTextColor | CustomLegendColor;
};

const COLORS_TITLE_MODS: ColorsTitleMods = {
  [SUMMARY_ID.IDENTICAL_AMINOACID]: 'green',
  [SUMMARY_ID.CONSERVATIVE_REPLACEMENT]: 'yellow',
  [SUMMARY_ID.NONCONSERVATIVE_REPLACEMENT]: 'red',
};

export interface SummaryLegendListProps {
  summary: SummaryItem[];
  margin?: string;
  width?: string;
}
export const SummaryLegendList: React.FC<SummaryLegendListProps> = ({
  summary,
  margin,
  width,
}: SummaryLegendListProps) => {
  const legendIndex = summary.findIndex((el) => !el.value);
  const summaryArr = summary.slice(0, legendIndex);
  const legendArr = summary.slice(legendIndex + 1);
  return (
    <Wrapper margin={margin} width={width}>
      <Header>Summary</Header>
      <List>
        {summaryArr.map(({ value, title, id }) => {
          const boldText = SUMMARY_ID.AMINOACID_PERCENTAGE_HOMOLOGY === id;
          let customColor: CustomTextColor | CustomLegendColor | undefined;
          if (id in COLORS_TITLE_MODS) customColor = getKeyValue(COLORS_TITLE_MODS)(id);
          return (
            <ListItem key={id}>
              <Element customColor={customColor}>{title}</Element>
              <Element boldText={boldText}>{value}</Element>
            </ListItem>
          );
        })}
      </List>
      <Header>Legend</Header>
      <List>
        {legendArr.map(({ value, title, id }) => (
          <ListItem key={id}>
            <Element>{title}</Element>
            <Element>{value}</Element>
          </ListItem>
        ))}
      </List>
    </Wrapper>
  );
};
