/* eslint-disable no-restricted-syntax */
type GetMapKeyByValue = string | null;

export const getMapKeyByValue = (
  map: Map<string, string>,
  compareValue: string,
): GetMapKeyByValue => {
  let searchedKey: GetMapKeyByValue = null;
  for (const [key, value] of Array.from(map.entries())) {
    if (compareValue === value) {
      searchedKey = key;
      break;
    }
  }
  return searchedKey;
};
