/* eslint-disable object-curly-newline */
import * as React from 'react';
import { useParams } from 'react-router-dom';
import { CustomizeTableRow, CustomTable } from '../../UI/molecules';
import { PageTemplate } from '../../templates';
import {
  MainHeader,
  PreviousPageBtn,
  MoleculeStructure,
  TRNApropertiesLegend,
  SingleLinkWrapper,
  MoleculeWrapper,
} from '../../UI/atoms';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { RoutingURLs } from '../../../routing';
import { useActions } from '../../../hooks/use-actions';
import {
  AvailableTRNAsingleLinkIDs,
  customWidth,
  TRNApropertiesSingleLinkElement,
  UrlParams,
} from '../../../state/tRNApropertiesSingleLink';
import { getMapKeyByValue, getCustomTextColor } from '../../../utils';

export interface TRNApropertiesSingleLinkProps {}
export const TRNApropertiesSingleLink: React.FC<TRNApropertiesSingleLinkProps> = () => {
  const tRNApropertiesState = useTypedSelector(({ tRNAproperties }) => tRNAproperties);
  const tRNApropertiesSingleLinkState = useTypedSelector(
    ({ tRNApropertiesSingleLink }) => tRNApropertiesSingleLink,
  );
  const dictionariesState = useTypedSelector(({ dictionaries }) => dictionaries);
  const generateXLSXfileState = useTypedSelector(({ generateXLSXfile }) => generateXLSXfile);
  const {
    fetchTRNApropertiesSingleLink,
    setTRNApropertiesSingleLinkTableColumns,
    downloadXLSXFile,
    enableDnaAPILoader,
    disableDnaAPILoader,
  } = useActions();
  const { linkID }: UrlParams = useParams();

  const currentID =
    tRNApropertiesState?.geneLinks && getMapKeyByValue(tRNApropertiesState?.geneLinks, linkID);

  React.useEffect(() => {
    if (linkID !== tRNApropertiesSingleLinkState?.id && currentID) {
      fetchTRNApropertiesSingleLink(currentID);
    }
    if (currentID) document.title = currentID;
  }, [tRNApropertiesSingleLinkState?.id, currentID]);

  const tableDataset = React.useMemo<TRNApropertiesSingleLinkElement[]>(
    () => tRNApropertiesSingleLinkState?.data || [],
    [tRNApropertiesSingleLinkState?.data],
  );

  React.useEffect(() => {
    if (tRNApropertiesSingleLinkState?.loading) enableDnaAPILoader();
    else disableDnaAPILoader();
  }, [tRNApropertiesSingleLinkState?.loading]);

  const handleDownloadXLSXfile = () => {
    if (generateXLSXfileState?.loading) return; // 🔥 do not fetch API call when active 🔥
    const tableColumns = tRNApropertiesSingleLinkState?.columns.table || [];
    const data = tRNApropertiesSingleLinkState?.data || [];
    const name = (currentID && tRNApropertiesState?.geneLinks.get(currentID)) || '';
    const mergedFirstRowHeaderCells = true;
    downloadXLSXFile({
      tableColumns,
      data,
      name,
      mergedFirstRowHeaderCells,
    });
  };

  const isDownloadingXLSXfile = generateXLSXfileState?.loading || false;

  const dictColumnWidth = {
    [AvailableTRNAsingleLinkIDs.DOG_GENOMIC_POSITION]: customWidth.MEDIUM,
    [AvailableTRNAsingleLinkIDs.DOG_5_3_STRAND]: customWidth.SMALL,
    [AvailableTRNAsingleLinkIDs.DOG_3_5_STRAND]: customWidth.SMALL,
    [AvailableTRNAsingleLinkIDs.DOG_GENE_POSITION]: customWidth.MEDIUM,
    [AvailableTRNAsingleLinkIDs.DOG_SECONDARY_STRUCTURE]: customWidth.LARGE,
    [AvailableTRNAsingleLinkIDs.HUMAN_GENOMIC_POSITION]: customWidth.MEDIUM,
    [AvailableTRNAsingleLinkIDs.HUMAN_5_3_STRAND]: customWidth.SMALL,
    [AvailableTRNAsingleLinkIDs.HUMAN_3_5_STRAND]: customWidth.SMALL,
    [AvailableTRNAsingleLinkIDs.HUMAN_GENE_POSITION]: customWidth.MEDIUM,
    [AvailableTRNAsingleLinkIDs.HUMAN_SECONDARY_STRUCTURE]: customWidth.LARGE,
  };

  const customizeTableRow: CustomizeTableRow[] = [
    {
      id: AvailableTRNAsingleLinkIDs.DOG_SECONDARY_STRUCTURE,
      setValueTwoLinesWithTooltip: true,
    },
    {
      id: AvailableTRNAsingleLinkIDs.HUMAN_SECONDARY_STRUCTURE,
      setValueTwoLinesWithTooltip: true,
    },
    {
      id: AvailableTRNAsingleLinkIDs.DOG_5_3_STRAND,
      getCustomTextColor,
    },
    {
      id: AvailableTRNAsingleLinkIDs.HUMAN_5_3_STRAND,
      getCustomTextColor,
    },
    {
      id: AvailableTRNAsingleLinkIDs.SEQUENCE_HOMOLOGY,
      getCustomTextColor,
    },
  ];

  return (
    <>
      <PageTemplate>
        <PreviousPageBtn to={`/${RoutingURLs.TRNA_PROPERTIES}`} />
        <MainHeader>{currentID || 'tRNA'}</MainHeader>
        {tRNApropertiesSingleLinkState?.loading ? null : (
          <SingleLinkWrapper>
            <MoleculeWrapper>
              {tRNApropertiesSingleLinkState?.id && (
                <MoleculeStructure
                  src={tRNApropertiesSingleLinkState.imgURL}
                  alt={`Dog (left) & human (right) ${tRNApropertiesSingleLinkState.id}`}
                />
              )}
              <TRNApropertiesLegend />
            </MoleculeWrapper>
            {tRNApropertiesSingleLinkState?.columns.selected &&
              dictionariesState?.data.tRNApropertiesSingleLink.columnNames && (
                <CustomTable
                  data={tableDataset}
                  columnParams={{
                    selected: tRNApropertiesSingleLinkState?.columns.selected,
                    groupSelected: tRNApropertiesSingleLinkState.columns.groupSelected,
                    table: tRNApropertiesSingleLinkState.columns.table,
                    setTableColumns: setTRNApropertiesSingleLinkTableColumns,
                    dictColumnNames: dictionariesState?.data.tRNApropertiesSingleLink.columnNames,
                    dictColumnWidth,
                  }}
                  customizeTableRow={customizeTableRow}
                  generateXLSX={{
                    handleDownload: handleDownloadXLSXfile,
                    isDownloading: isDownloadingXLSXfile,
                  }}
                />
              )}
          </SingleLinkWrapper>
        )}
      </PageTemplate>
    </>
  );
};
