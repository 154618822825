import { Dispatch } from 'redux';
import { Action } from '../actions';
import { ActionType } from '../action-types';
import { RootState } from '../reducers';

export const persistMiddleware =
  ({ dispatch, getState }: { dispatch: Dispatch<Action>; getState: () => RootState }) =>
  (next: (action: Action) => void) =>
  (action: Action) => {
    next(action); // no matter what put next action!

    const { dictionaries, errors } = getState();

    if (!errors?.isInternalServerError500 && dictionaries?.error.code === 500) {
      dispatch({
        type: ActionType.SET_INTERNAL_SERVER_ERROR_500,
      });
    }
  };
