import { SummaryItem } from './tRNApropertiesSingleLink';

export enum AvailableProteinSingleLinkIDs {
  POSITION = 'position',
  DOG_MT_CYB = 'dogMtCyb',
  HUMAN_MT_CYB = 'humanMtCyb',
  DOG_AMINOACID_PROPERTIES = 'dogAminoacidProperties',
  HUMAN_AMINOACID_PROPERTIES = 'humanAminoacidProperties',
  SIMILARITY = 'similarity',
}

export interface ProteinPropertiesSingleLinkElement {
  [AvailableProteinSingleLinkIDs.POSITION]?: string;
  [AvailableProteinSingleLinkIDs.DOG_MT_CYB]?: string;
  [AvailableProteinSingleLinkIDs.HUMAN_MT_CYB]?: string;
  [AvailableProteinSingleLinkIDs.DOG_AMINOACID_PROPERTIES]?: string;
  [AvailableProteinSingleLinkIDs.HUMAN_AMINOACID_PROPERTIES]?: string;
  [AvailableProteinSingleLinkIDs.SIMILARITY]?: string;
}

export interface FetchProteinPropertiesSingleLinkParams {
  dataset: ProteinPropertiesSingleLinkElement[];
  summary: SummaryItem[];
}

export enum SUMMARY_ID {
  IDENTICAL_AMINOACID = 2,
  CONSERVATIVE_REPLACEMENT = 3,
  NONCONSERVATIVE_REPLACEMENT = 4,
  PROTEIN_LENGTH = 5,
  AMINOACID_PERCENTAGE_HOMOLOGY = 6,
  // legend
  PROPERTIES = 9,
  SPECIAL = 10,
  NEUTRAL_AND_S = 11,
  POLAR_AND_RS = 12,
  POLAR_AND_RL = 13,
  NONPOLAR_AND_RS = 14,
  NONPOLAR_AND_RL = 15,
}

export interface FetchProteinPropertiesSingleLinkCompletePayload {
  id: string;
  dataset: ProteinPropertiesSingleLinkElement[];
  summary: SummaryItem[];
}
