import * as React from 'react';
import { BiCheck, BiColumns } from 'react-icons/bi';
import { MdClose } from 'react-icons/md';
import styled, { css } from 'styled-components';
import { ColumnParams } from '..';
import { AvailableCanisColumnIDs } from '../../../../state/canisSNPfinder';
import { AvailableTRNAColumnIDs } from '../../../../state/tRNAproperties';
import { ButtonIcon } from '../../atoms';

const HideMenuWrapper = styled.button`
  border: none;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.2);
  cursor: pointer;
`;

const MenuWrapper = styled.div`
  z-index: 1;
  position: absolute;
  left: 43px;
  top: 2px;
  border: 2px solid black;
  background-color: white;
  min-width: 180px;
  min-height: 100px;

  /* black triangle */
  &::after {
    content: '';
    position: absolute;
    top: 5px;
    left: -10px;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid black;
  }
`;

const Header = styled.header`
  background-color: black;
  color: white;
  padding: 0 5px 6px 10px;
  font-size: 1.5rem;
  position: relative;
`;

const CloseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.2rem;
  cursor: pointer;
  padding: 3px;

  position: absolute;
  right: 0;
  top: -1px;
  background: transparent;
  color: white;
  border: none;

  transition: transform 0.3s;
  &:hover {
    transform: scale(1.3);
  }
`;

const List = styled.ul`
  list-style-type: none;
  height: 185px;
  overflow: auto;
`;

const ListItem = styled.li``;

const ICON_WIDTH = '30px';

const StyledCheckIcon = styled(BiCheck)`
  font-size: 2rem;
  min-width: ${ICON_WIDTH};
`;

interface ColumnNameProps {
  isActive?: boolean;
}

const ColumnName = styled.span<ColumnNameProps>`
  text-align: left;
  ${({ isActive }) =>
    !isActive &&
    css`
      margin-left: ${ICON_WIDTH};
    `}
`;

const buttonStyles = css`
  border: none;
  background: transparent;
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.main};
  transition: background-color 0.2s;

  &:hover {
    background-color: hsl(214, 95%, 81%);
  }
`;

const ListItemButton = styled.button`
  ${buttonStyles}
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 4px;
`;

interface TextButtonProps {
  grayBorder?: boolean;
}

const TextButton = styled.button<TextButtonProps>`
  ${buttonStyles}
  text-align: left;
  padding: 4px 0 4px 15px;
  font-size: 1.5rem;

  ${({ grayBorder }) =>
    grayBorder &&
    css`
      box-shadow: 0 1px 0 0 #ddd;
    `}
`;

const ColumnsMenuFooter = styled.footer`
  display: flex;
  flex-direction: column;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background-color: black;
  }
`;

export interface ColumnsSelection {
  active: boolean;
  availableColumnIDs: AvailableCanisColumnIDs[] | AvailableTRNAColumnIDs[];
  basic: string[];
  setSelected: Function;
}

type ColumnID = AvailableCanisColumnIDs | AvailableTRNAColumnIDs;

export interface ColumnsMenuProps {
  columnsSelection: ColumnsSelection;
  columnParams: ColumnParams;
}
export const ColumnsMenu: React.FC<ColumnsMenuProps> = ({
  columnsSelection,
  columnParams,
}: ColumnsMenuProps) => {
  const [showMenuWrapper, setShowMenuWrapper] = React.useState(false);

  const { selected, dictColumnNames } = columnParams;
  const { availableColumnIDs, setSelected, basic } = columnsSelection;

  const activeColumnsCounter = selected.length || 0;

  const escFunction = (event: KeyboardEvent) => {
    if (event.key === 'Escape') setShowMenuWrapper(false);
  };
  React.useEffect(() => {
    document.addEventListener('keydown', escFunction);
    return () => {
      document.removeEventListener('keydown', escFunction);
    };
  }, []);

  const toggleMenu = () => {
    setShowMenuWrapper(!showMenuWrapper);
  };

  const selectAll = () => {
    if (selected.length !== availableColumnIDs.length) {
      setSelected(columnsSelection.availableColumnIDs);
    }
  };

  const selectBasic = () => {
    setSelected(basic);
  };

  const handleListItemButton = (id: ColumnID) => {
    const newSelected = [...selected];
    if (selected.includes(id)) {
      // remove item from array
      const index = selected.findIndex((colID) => colID === id);
      newSelected.splice(index, 1);
    } else {
      // push to array (based on corresponding index from availableColumnsIDs)
      const index = availableColumnIDs.findIndex((colID: ColumnID) => colID === id);
      newSelected.splice(index, 0, id);
    }
    setSelected(newSelected);
  };

  return (
    <>
      <ButtonIcon tooltip="Show/hide columns" Icon={BiColumns} onClick={toggleMenu} />
      {showMenuWrapper && (
        <>
          <HideMenuWrapper onClick={toggleMenu} />
          <MenuWrapper>
            <Header>
              {activeColumnsCounter}/{availableColumnIDs.length}
              <CloseButton onClick={toggleMenu}>
                <MdClose />
              </CloseButton>
            </Header>
            <List>
              {availableColumnIDs.map((columnID: ColumnID) => {
                const isActive = selected.includes(columnID);
                const name = dictColumnNames[columnID];
                return (
                  <ListItem key={columnID}>
                    <ListItemButton onClick={() => handleListItemButton(columnID)}>
                      {isActive && <StyledCheckIcon />}
                      <ColumnName isActive={isActive}>{name}</ColumnName>
                    </ListItemButton>
                  </ListItem>
                );
              })}
            </List>
            <ColumnsMenuFooter>
              <TextButton onClick={selectAll} grayBorder>
                Select all
              </TextButton>
              <TextButton onClick={selectBasic}>Restore default columns</TextButton>
            </ColumnsMenuFooter>
          </MenuWrapper>
        </>
      )}
    </>
  );
};
