/**
 *
 * @param disposition - from response.headers['content-disposition'];
 *
 * @param defaultFilename - string for filename when something went wrong with disposition!
 * @returns
 *
 * Example of using:
 *    const disposition: string = response.headers['content-disposition'];
 *     const filename = getFileName(disposition, 'canis SNP finder.xlsx');
 */
export const getFilename = (disposition: string, defaultFilename: string): string => {
  let filename = defaultFilename;
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};
