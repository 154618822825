/* eslint-disable no-param-reassign */
import produce from 'immer';
import { Column } from 'react-table';
import { ActionType } from '../../action-types';
import { Action } from '../../actions';
import { MRNApropertiesElement } from '../../mRNAproperties';

export interface MRNApropertiesState {
  data: MRNApropertiesElement[];
  columns: {
    selected: string[];
    table: Column<MRNApropertiesElement>[];
  };
  isDataPrepared: boolean;
  geneLinks: Map<string, string>;
}

export const initialState: MRNApropertiesState = {
  data: [],
  columns: {
    selected: [],
    table: [],
  },
  isDataPrepared: false,
  geneLinks: new Map(),
};

const reducer = produce((state: MRNApropertiesState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.SET_MRNA_PROPERTIES_DATA:
      state.data = action.payload;
      state.isDataPrepared = true;
      return state;
    case ActionType.SET_MRNA_PROPERTIES_SELECTED_COLUMNS:
      state.columns.selected = action.payload;
      return state;
    case ActionType.SET_MRNA_PROPERTIES_TABLE_COLUMNS:
      state.columns.table = action.payload;
      return state;
    case ActionType.SET_MRNA_PROPERTIES_GENE_LINKS:
      state.geneLinks = action.payload;
      return state;
    default:
      return state;
  }
});

export default reducer;
