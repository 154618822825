/* eslint-disable object-curly-newline */
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import reducers from './reducers';
import { persistMiddleware } from './middlewares/persist-middleware';

const composeEnhancers = composeWithDevTools({
  serialize: true,
});
export const store = createStore(
  reducers,
  {},
  composeEnhancers(applyMiddleware(persistMiddleware, thunk)),
);
