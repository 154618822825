export enum AvailableTRNAsingleLinkIDs {
  DOG_GENOMIC_POSITION = 'dogGenomicPosition',
  DOG_5_3_STRAND = 'dog53strand',
  DOG_3_5_STRAND = 'dog35strand',
  DOG_GENE_POSITION = 'dogGenePosition',
  DOG_SECONDARY_STRUCTURE = 'dogSecondaryStructure',
  HUMAN_GENOMIC_POSITION = 'humanGenomicPosition',
  HUMAN_5_3_STRAND = 'human53strand',
  HUMAN_3_5_STRAND = 'human35strand',
  HUMAN_GENE_POSITION = 'humanGenePosition',
  HUMAN_SECONDARY_STRUCTURE = 'humanSecondaryStructure',
  SEQUENCE_HOMOLOGY = 'sequenceHomology',
}

export interface TRNApropertiesSingleLinkElement {
  [AvailableTRNAsingleLinkIDs.DOG_GENOMIC_POSITION]: string;
  [AvailableTRNAsingleLinkIDs.DOG_5_3_STRAND]: string;
  [AvailableTRNAsingleLinkIDs.DOG_3_5_STRAND]: string;
  [AvailableTRNAsingleLinkIDs.DOG_GENE_POSITION]: string;
  [AvailableTRNAsingleLinkIDs.DOG_SECONDARY_STRUCTURE]: string;
  [AvailableTRNAsingleLinkIDs.HUMAN_GENOMIC_POSITION]: string;
  [AvailableTRNAsingleLinkIDs.HUMAN_5_3_STRAND]: string;
  [AvailableTRNAsingleLinkIDs.HUMAN_3_5_STRAND]: string;
  [AvailableTRNAsingleLinkIDs.HUMAN_GENE_POSITION]: string;
  [AvailableTRNAsingleLinkIDs.HUMAN_SECONDARY_STRUCTURE]: string;
  [AvailableTRNAsingleLinkIDs.SEQUENCE_HOMOLOGY]: string;
}

export interface FetchTRNApropertiesSingleLinkParams {
  dataset: TRNApropertiesSingleLinkElement[];
  imgURL: string;
  summary: SummaryItem[];
}

export interface SummaryItem {
  id: number;
  title: string;
  value: string | null;
}

export enum SUMMARY_ID {
  TRANSITIONS = 3,
  TRANSVERATIONS = 4,
  GAPS = 5,
  NUMBER_POSITIONS = 6,
  LENGTH = 7,
  PERCENTAGE_HOMOLOGY = 8,
}

export enum URL_PARAMS {
  LINK_ID = 'linkID',
}

export interface UrlParams {
  [URL_PARAMS.LINK_ID]: string;
}

export enum customWidth {
  SMALL = 68,
  MEDIUM = 70,
  LARGE = 110,
}

export interface FetchTRNAPropertiesSingleLinkCompletePayload {
  id: string;
  dataset: TRNApropertiesSingleLinkElement[];
  imgURL: string;
  summary: SummaryItem[];
}
