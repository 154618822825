import * as React from 'react';
import styled from 'styled-components';
import { ErrorMessage, ContentFlexWrapper } from '..';

const StyledParagraph = styled.p``;

const StyledExamples = styled.p`
  color: hsl(0, 0%, 30%);
  font-size: 0.8em;
`;

const Input = styled.input`
  border: 2px solid black;
  background-color: white;
  font-family: ${({ theme }) => theme.fontFamily.main};
  padding: 2px 4px;
  font-size: 1.4rem;
  min-width: 250px;

  &:disabled {
    background-color: #ddd;
    border-color: #6e6e6e;
    cursor: not-allowed;
    color: black;
  }
`;

const Label = styled.label`
  cursor: pointer;
`;

const LabelInfo = styled.span`
  padding: 0 5px 0 15px;
`;

export interface StyledTextInputProps {
  paragraphDescription: string;
  id: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  examplesDescription?: string;
  error?: string;
  placeholder?: string;
  additionalCheckbox?: {
    label: string;
    value: boolean;
    onChange: React.ChangeEventHandler<HTMLInputElement>;
    showOnly?: boolean;
  };
}

export const StyledTextInput: React.FC<StyledTextInputProps> = ({
  paragraphDescription,
  id,
  value,
  onChange,
  examplesDescription,
  error,
  placeholder,
  additionalCheckbox,
}: StyledTextInputProps) => {
  const showAdditionalCheckbox = !!additionalCheckbox?.showOnly;

  return (
    <>
      <StyledParagraph>{paragraphDescription}</StyledParagraph>
      {examplesDescription && <StyledExamples>{examplesDescription}</StyledExamples>}
      <ContentFlexWrapper
        alignItems="center"
        justifyContent="flex-start"
        flexDirection="row"
        bottomMargin
        flexWrap="wrap"
      >
        <Input
          id={id}
          disabled={additionalCheckbox?.value}
          name={id}
          autoComplete="off"
          type="text"
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
        {error && <ErrorMessage>{error}</ErrorMessage>}
        {additionalCheckbox && showAdditionalCheckbox && (
          <Label>
            <LabelInfo>{additionalCheckbox.label}</LabelInfo>
            <input
              type="checkbox"
              checked={additionalCheckbox.value}
              onChange={additionalCheckbox.onChange}
            />
          </Label>
        )}
      </ContentFlexWrapper>
    </>
  );
};
