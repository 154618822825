import { Column } from 'react-table';
import { FormNames } from '../global/types';
import { TableSingleLinkColumnProps } from './generateXLSXfile';
import { TRNApropertiesElement } from './tRNAproperties';

export interface CanisSNPFinderParams {
  [FormNames.COMPARISON_DIRECTION]: string;
  [FormNames.COMPARED_POSITION]: string;
  [FormNames.CHOOSED_GENE]: string;
  [FormNames.POSITION_NUMBER]: string;
  [FormNames.POSITION_NUMBER_SHOW_ALL]: boolean;
}

// ⚠️ interface same as in [server]
export enum AvailableCanisColumnIDs {
  ID = 'id',
  GENOME_POSITION = 'genomePosition',
  DOG_MTDNA_5_3_STRAND = 'dogMtDNA53strand',
  DOG_MTDNA_3_5_STRAND = 'dogMtDNA35strand',
  TYPE = 'type',
  GENE = 'gene',
  GENE_POSITION = 'genePosition',
  POSITION_CODON = 'positionCodon',
  CODON = 'codon',
  AMINOACID_POSITION = 'aminoacidPosition',
  AMINOACID_1_LETTER = 'aminoacid1letter',
  AMINOACID_3_LETTER = 'aminoacid3letter',
  SOPMA = 'sopma',
  TMHMM = 'tmhmm',
  CONSERVATION_GRADE = 'conversationGrade',
  BURRIED_OR_EXPOSED_RESIDUE = 'burriedOrExposedResidue',
  FUNCTION = 'function',
  TRNA_SCAN = 'tRNAscan',
  SECOND_STRUCTURE_FEP = 'secondStructureFEP',
  SECOND_STRUCTURE_TEP = 'secondStructureTEP',
  HUMAN_MTDNA_POSITION = 'humanMtDNAposition',
  HUMAN_MTDNA_REF_SEQ = 'humanMtDNArefSeq',
  IDENTICAL_DIFFERENT = 'identicalDifferent',
  HUMAN_GENE = 'humanGene',
}
// ⚠️ interface same as in [server]

export interface CanisSNPFinderElement {
  [AvailableCanisColumnIDs.ID]: string;
  [AvailableCanisColumnIDs.GENOME_POSITION]: string;
  [AvailableCanisColumnIDs.DOG_MTDNA_5_3_STRAND]: string;
  [AvailableCanisColumnIDs.DOG_MTDNA_3_5_STRAND]: string;
  [AvailableCanisColumnIDs.TYPE]: string;
  [AvailableCanisColumnIDs.GENE]: string;
  [AvailableCanisColumnIDs.GENE_POSITION]: string;
  [AvailableCanisColumnIDs.POSITION_CODON]: string;
  [AvailableCanisColumnIDs.CODON]: string;
  [AvailableCanisColumnIDs.AMINOACID_POSITION]: string;
  [AvailableCanisColumnIDs.AMINOACID_1_LETTER]: string;
  [AvailableCanisColumnIDs.AMINOACID_3_LETTER]: string;
  [AvailableCanisColumnIDs.SOPMA]: string;
  [AvailableCanisColumnIDs.TMHMM]: string;
  [AvailableCanisColumnIDs.CONSERVATION_GRADE]: string;
  [AvailableCanisColumnIDs.BURRIED_OR_EXPOSED_RESIDUE]: string;
  [AvailableCanisColumnIDs.FUNCTION]: string;
  [AvailableCanisColumnIDs.TRNA_SCAN]: string;
  [AvailableCanisColumnIDs.SECOND_STRUCTURE_FEP]: string;
  [AvailableCanisColumnIDs.SECOND_STRUCTURE_TEP]: string;
  [AvailableCanisColumnIDs.HUMAN_MTDNA_POSITION]: string;
  [AvailableCanisColumnIDs.HUMAN_MTDNA_REF_SEQ]: string;
  [AvailableCanisColumnIDs.IDENTICAL_DIFFERENT]: string;
  [AvailableCanisColumnIDs.HUMAN_GENE]: string;
}

export interface FetchCanisSNPFinderPayload {
  dataset: CanisSNPFinderElement[];
  columns: AvailableCanisColumnIDs[];
}

export interface SetCanisSNPFinderColumnsPayload {
  selected: AvailableCanisColumnIDs[];
  tableColumns:
    | Column<CanisSNPFinderElement>[]
    | Column<TableSingleLinkColumnProps>[]
    | Column<TRNApropertiesElement>[];
}

export enum customWidth {
  SMALL = 55,
  XS_SMALL = 65,
  X_SMALL = 75,
  XL_SMALL = 85,
  MEDIUM = 95,
  XS_MEDIUM = 125,
  X_MEDIUM = 110,
  XL_MEDIUM = 115,
  LARGE = 120,
  XS_LARGE = 125,
  X_LARGE = 165,
  XL_LARGE = 260,
}
