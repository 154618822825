import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { StyledRadioInput, radioElement, StyledSelect, StyledTextInput, Button } from '../../atoms';
import { FormNames, FormIDs } from '../../../../global/types';
import { useActions } from '../../../../hooks/use-actions';
import { CanisSNPFinderParams } from '../../../../state/canisSNPfinder';
import { useTypedSelector } from '../../../../hooks/use-typed-selector';

const comparisonDirectionOptions: radioElement[] = [
  {
    name: 'comparisonDirection',
    id: 'human-canine',
    label: 'Human -> canine',
  },
  {
    name: 'comparisonDirection',
    id: 'canine-human',
    label: 'Canine -> human',
  },
];

const comparedPositionOptions: radioElement[] = [
  {
    name: 'comparedPosition',
    id: 'SNP-genome',
    label: 'SNP position in the genome',
  },
  {
    name: 'comparedPosition',
    id: 'SNP-gene',
    label: 'SNP position in specific gene',
  },
];

const StyledForm = styled.form`
  padding-left: 15%;
  margin-bottom: 10px;
`;

export interface CanisSNPFinderFormProps {}

export const CanisSNPFinderForm: React.FC<CanisSNPFinderFormProps> = () => {
  const { fetchCanisSNPfinder } = useActions();

  const dictionariesState = useTypedSelector(({ dictionaries }) => dictionaries);
  const canisSNPFinderState = useTypedSelector(({ canisSNPFinder }) => canisSNPFinder);

  const initialValues: CanisSNPFinderParams = {
    comparisonDirection: '',
    comparedPosition: '',
    choosedGene: '',
    positionNumber: '',
    positionNumberShowAll: false,
  };

  const validationSchema: Yup.SchemaOf<CanisSNPFinderParams> = Yup.object({
    comparisonDirection: Yup.string().required('This field is required.'),
    comparedPosition: Yup.string().required('This field is required.'),
    choosedGene: Yup.string()
      .default('')
      .when('comparedPosition', {
        is: FormIDs.SNP_IN_GENE,
        then: Yup.string().required('This field is required.'),
      }),
    positionNumber: Yup.string()
      .default('')
      .when(FormNames.POSITION_NUMBER_SHOW_ALL, {
        is: false,
        then: Yup.string()
          .required('This field is required.')
          .matches(/^[,0-9 -]+$/, 'Wrong format. Check to see examples.'),
      }),
    positionNumberShowAll: Yup.boolean().default(false),
  }).defined();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      if (canisSNPFinderState?.loading) return; // 🔥 do not fetch API call when active 🔥
      fetchCanisSNPfinder(values);
    },
  });

  const selectOptions =
    formik.values.comparisonDirection === FormIDs.HUMAN_TO_CANINE
      ? dictionariesState?.data.canisSNPFinder.gene?.humanCanine
      : dictionariesState?.data.canisSNPFinder.gene?.canineHuman;

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    formik.setFieldValue(FormNames.CHOOSED_GENE, e.target.value);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(FormNames.POSITION_NUMBER_SHOW_ALL, e.target.checked);
  };

  useEffect(() => {
    if (selectOptions) {
      if (formik.values.comparedPosition === FormIDs.SNP_IN_GENOME) {
        formik.setFieldValue(FormNames.CHOOSED_GENE, '');
        formik.setFieldValue(FormNames.POSITION_NUMBER_SHOW_ALL, false);
      } else if (formik.values.comparedPosition === FormIDs.SNP_IN_GENE) {
        formik.setFieldValue(FormNames.CHOOSED_GENE, selectOptions[0]);
      }
    }
  }, [formik.values.comparedPosition]);

  useEffect(() => {
    if (selectOptions && formik.values.comparedPosition === FormIDs.SNP_IN_GENE) {
      formik.setFieldValue(FormNames.CHOOSED_GENE, selectOptions[0]);
    }
  }, [formik.values.comparisonDirection]);

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <StyledRadioInput
        radioOptions={comparisonDirectionOptions}
        onChange={formik.handleChange}
        paragraphDescription="1. Please select the comparison direction:"
        error={formik.errors.comparisonDirection}
      />
      <StyledRadioInput
        radioOptions={comparedPositionOptions}
        onChange={formik.handleChange}
        paragraphDescription="2. Please select which position has to be compared:"
        error={formik.errors.comparedPosition}
      />
      {formik.values.choosedGene &&
        formik.values.comparedPosition === FormIDs.SNP_IN_GENE &&
        selectOptions && (
          <StyledSelect
            selectOptions={selectOptions}
            id={FormNames.CHOOSED_GENE}
            value={formik.values.choosedGene}
            paragraphDescription="2b. Please choose the gene:"
            onChange={handleSelectChange}
            error={formik.errors.choosedGene}
          />
        )}
      <StyledTextInput
        paragraphDescription="3. Please enter the position number (human genome between 1 and 16569 / canine genome between 1 and 16727)"
        examplesDescription="Examples: 2578 / 345, 346, 347 / 213 - 220"
        placeholder="1 - 20"
        id={FormNames.POSITION_NUMBER}
        value={formik.values.positionNumber}
        onChange={formik.handleChange}
        error={formik.errors.positionNumber}
        additionalCheckbox={{
          label: 'Show all',
          value: formik.values.positionNumberShowAll,
          onChange: handleCheckboxChange,
          showOnly: formik.values.comparedPosition === FormIDs.SNP_IN_GENE,
        }}
      />
      <Button type="submit" margin="0">
        Search
      </Button>
    </StyledForm>
  );
};
