/* eslint-disable no-param-reassign */
import produce from 'immer';
import { Column } from 'react-table';
import { ActionType } from '../../action-types';
import { Action } from '../../actions';
import { AvailableCanisColumnIDs, CanisSNPFinderElement } from '../../canisSNPfinder';

export interface CanisSNPFinderState {
  loading: boolean;
  error: string | null;
  data: CanisSNPFinderElement[];
  isDataPrepared: boolean;
  columns: {
    selected: AvailableCanisColumnIDs[];
    basic: AvailableCanisColumnIDs[];
    table: Column<CanisSNPFinderElement>[];
  };
}

export const initialState: CanisSNPFinderState = {
  loading: false,
  error: null,
  data: [],
  isDataPrepared: false,
  columns: {
    selected: [],
    basic: [],
    table: [],
  },
};

const reducer = produce((state: CanisSNPFinderState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.FETCH_CANIS_SNP_FINDER:
      state.loading = true;
      return state;
    case ActionType.FETCH_CANIS_SNP_FINDER_COMPLETE:
      state.loading = false;
      state.data = action.payload.dataset;
      state.columns.basic = action.payload.columns;
      state.columns.selected = action.payload.columns;
      state.isDataPrepared = true;
      state.error = null;
      return state;
    case ActionType.FETCH_CANIS_SNP_FINDER_ERROR:
      state.loading = false;
      state.error = action.payload;
      return state;
    case ActionType.SET_CANIS_SNP_FINDER_SELECTED_COLUMNS:
      state.columns.selected = action.payload;
      return state;
    case ActionType.SET_CANIS_SNP_FINDER_TABLE_COLUMNS:
      state.columns.table = action.payload;
      return state;
    case ActionType.CLEAR_CANIS_SNP_FINDER_DATA:
      state.loading = false;
      state.data = [];
      state.columns.basic = [];
      state.columns.selected = [];
      state.columns.table = [];
      state.isDataPrepared = false;
      state.error = null;
      return state;
    default:
      return state;
  }
});

export default reducer;
