/* eslint-disable object-curly-newline */
import axios, { AxiosError } from 'axios';
import { Column } from 'react-table';
import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import { setErrorToastMessage } from '../../components/UI/atoms';
import { GroupSelected } from '../../components/UI/molecules';
import { getFilename, prepareLink } from '../../utils';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import {
  AvailableCanisColumnIDs,
  CanisSNPFinderElement,
  CanisSNPFinderParams,
  FetchCanisSNPFinderPayload,
} from '../canisSNPfinder';
import { FetchDictionariesCompletePayload, FetchDictionariesParams } from '../dictionaries';
import { GenerateXLSXProps, TableSingleLinkColumnProps } from '../generateXLSXfile';
import { MRNApropertiesElement } from '../mRNAproperties';
import { FetchMRNApropertiesSingleLinkParams } from '../mRNApropertiesSingleLink';
import { ProteinPropertiesElement } from '../proteinProperties';
import { FetchProteinPropertiesSingleLinkParams } from '../proteinPropertiesSingleLink';
import { TRNApropertiesElement } from '../tRNAproperties';
import { FetchTRNApropertiesSingleLinkParams } from '../tRNApropertiesSingleLink';

export const enableDnaMainLoader = () => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionType.ENABLE_MAIN_LOADING,
  });
};

export const disableDnaMainLoader = () => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionType.DISABLE_MAIN_LOADING,
  });
};

export const enableDnaAPILoader = () => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionType.ENABLE_API_LOADING,
  });
};

export const disableDnaAPILoader = () => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionType.DISABLE_API_LOADING,
  });
};

export const clearCanisSNPFinderData = () => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionType.CLEAR_CANIS_SNP_FINDER_DATA,
  });
};

export const setCanisSNPFinderSelectedColumns =
  (payload: AvailableCanisColumnIDs[]) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_CANIS_SNP_FINDER_SELECTED_COLUMNS,
      payload,
    });
  };

export const setCanisSNPFinderTableColumns =
  (payload: Column<CanisSNPFinderElement>[]) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_CANIS_SNP_FINDER_TABLE_COLUMNS,
      payload,
    });
  };

export const fetchCanisSNPfinder =
  (params: CanisSNPFinderParams) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_CANIS_SNP_FINDER,
    });
    try {
      const {
        comparisonDirection,
        comparedPosition,
        positionNumber,
        choosedGene,
        positionNumberShowAll,
      } = params;
      const urlParams = `comparisonDirection=${comparisonDirection}&comparedPosition=${comparedPosition}&choosedGene=${choosedGene}&positionNumber=${positionNumber}&positionNumberShowAll=${positionNumberShowAll}`;
      const response = await axios.get(
        `${process.env.PUBLIC_URL}/api/canis-snp-finder?${urlParams}`,
      );
      const payload: FetchCanisSNPFinderPayload = response.data;

      dispatch({
        type: ActionType.FETCH_CANIS_SNP_FINDER_COMPLETE,
        payload,
      });
    } catch (error) {
      toast.error(setErrorToastMessage('Data cannot be displayed correctly.'), {
        icon: false,
      });
      const err = error as AxiosError;
      dispatch({
        type: ActionType.FETCH_CANIS_SNP_FINDER_ERROR,
        payload: err.message,
      });
    }
  };

export const fetchDictionaries = () => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionType.FETCH_DICTIONARIES,
  });
  try {
    const response = await axios.get(`${process.env.PUBLIC_URL}/api/dictionaries`);
    const {
      tRNAproperties,
      tRNApropertiesSingleLink,
      canisSNPFinder,
      mRNAproperties,
      mRNApropertiesSingleLink,
      proteinProperties,
      proteinPropertiesSingleLink,
    }: FetchDictionariesParams = response.data;
    const fetchPayload: FetchDictionariesCompletePayload = {
      canisSNPFinder,
      tRNAproperties: { columnNames: tRNAproperties.columnNames },
      tRNApropertiesSingleLink,
      mRNAproperties: { columnNames: mRNAproperties.columnNames },
      mRNApropertiesSingleLink,
      proteinProperties: { columnNames: proteinProperties.columnNames },
      proteinPropertiesSingleLink,
    };
    dispatch({
      type: ActionType.FETCH_DICTIONARIES_COMPLETE,
      payload: fetchPayload,
    });

    if (tRNAproperties.dataset) {
      const payload: TRNApropertiesElement[] = tRNAproperties.dataset;
      dispatch({
        type: ActionType.SET_TRNA_PROPERTIES_DATA,
        payload,
      });

      const geneLinks = new Map<string, string>();
      payload.forEach((value) => {
        geneLinks.set(value.gene, prepareLink(value.gene));
      });
      dispatch({
        type: ActionType.SET_TRNA_PROPERTIES_GENE_LINKS,
        payload: geneLinks,
      });
    }

    if (tRNAproperties.columnNames) {
      const payload = Object.keys(tRNAproperties.columnNames);
      dispatch({
        type: ActionType.SET_TRNA_PROPERTIES_SELECTED_COLUMNS,
        payload,
      });
    }

    if (tRNApropertiesSingleLink.columnNames) {
      const payload = Object.keys(tRNApropertiesSingleLink.columnNames);
      dispatch({
        type: ActionType.SET_TRNA_PROPERTIES_SINGLE_LINK_SELECTED_COLUMNS,
        payload,
      });

      const halfIndex = payload.length / 2;
      const lastIndex = payload.length - 1;
      const groupSelected: GroupSelected[] = [
        {
          Header: 'Dog',
          columnIDs: payload.slice(0, halfIndex),
        },
        {
          Header: 'Human',
          columnIDs: payload.slice(halfIndex, lastIndex),
        },
        {
          accessor: payload[lastIndex],
        },
      ];

      dispatch({
        type: ActionType.SET_TRNA_PROPERTIES_SINGLE_LINK_GROUP_SELECTED_COLUMNS,
        payload: groupSelected,
      });
    }

    if (mRNAproperties.dataset) {
      const payload: MRNApropertiesElement[] = mRNAproperties.dataset;
      dispatch({
        type: ActionType.SET_MRNA_PROPERTIES_DATA,
        payload,
      });

      const geneLinks = new Map<string, string>();
      payload.forEach((value) => {
        geneLinks.set(value.gene, prepareLink(value.gene));
      });
      dispatch({
        type: ActionType.SET_MRNA_PROPERTIES_GENE_LINKS,
        payload: geneLinks,
      });
    }

    if (mRNAproperties.columnNames) {
      const payload = Object.keys(mRNAproperties.columnNames);
      dispatch({
        type: ActionType.SET_MRNA_PROPERTIES_SELECTED_COLUMNS,
        payload,
      });
    }

    if (mRNApropertiesSingleLink.columnNames) {
      const payload = Object.keys(mRNApropertiesSingleLink.columnNames);
      dispatch({
        type: ActionType.SET_MRNA_PROPERTIES_SINGLE_LINK_SELECTED_COLUMNS,
        payload,
      });

      const halfIndex = payload.length / 2;
      const lastIndex = payload.length - 1;
      const groupSelected: GroupSelected[] = [
        {
          Header: 'Dog',
          columnIDs: payload.slice(0, halfIndex),
        },
        {
          Header: 'Human',
          columnIDs: payload.slice(halfIndex, lastIndex),
        },
        {
          accessor: payload[lastIndex],
        },
      ];

      dispatch({
        type: ActionType.SET_MRNA_PROPERTIES_SINGLE_LINK_GROUP_SELECTED_COLUMNS,
        payload: groupSelected,
      });
    }

    if (proteinProperties.dataset) {
      const payload: ProteinPropertiesElement[] = proteinProperties.dataset;
      dispatch({
        type: ActionType.SET_PROTEIN_PROPERTIES_DATA,
        payload,
      });

      const geneLinks = new Map<string, string>();
      payload.forEach((value) => {
        geneLinks.set(value.gene, prepareLink(value.gene));
      });
      dispatch({
        type: ActionType.SET_PROTEIN_PROPERTIES_GENE_LINKS,
        payload: geneLinks,
      });
    }

    if (proteinProperties.columnNames) {
      const payload = Object.keys(proteinProperties.columnNames);
      dispatch({
        type: ActionType.SET_PROTEIN_PROPERTIES_SELECTED_COLUMNS,
        payload,
      });
    }

    if (proteinPropertiesSingleLink.columnNames) {
      const payload = Object.keys(proteinPropertiesSingleLink.columnNames);
      dispatch({
        type: ActionType.SET_PROTEIN_PROPERTIES_SINGLE_LINK_SELECTED_COLUMNS,
        payload,
      });
    }
  } catch (error) {
    const err = error as AxiosError;
    dispatch({
      type: ActionType.FETCH_DICTIONARIES_ERROR,
      payload: {
        code: err.response?.status,
        serverResponse: err.message,
        clientResponse: err.response?.data,
      },
    });
  }
};

export const downloadXLSXFile =
  (params: GenerateXLSXProps) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.DOWNLOAD_XLSX_FILE,
    });
    try {
      const response = await axios.post(`${process.env.PUBLIC_URL}/api/generate-xlsx`, params, {
        responseType: 'blob',
      });

      if (response.status === 200) {
        const disposition: string = response.headers['content-disposition'];
        const filename = getFilename(disposition, `${params.name}.xlsx`);
        const blob = new Blob([response.data]);

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      dispatch({
        type: ActionType.DOWNLOAD_XLSX_FILE_COMPLETE,
      });
    } catch (error) {
      toast.error(setErrorToastMessage('Unable to download .xlsx file.'), {
        icon: false,
      });
      const err = error as AxiosError;
      dispatch({
        type: ActionType.DOWNLOAD_XLSX_FILE_ERROR,
        payload: err.message,
      });
    }
  };

export const setTRNApropertiesTableColumns =
  (payload: Column<TRNApropertiesElement>[]) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_TRNA_PROPERTIES_TABLE_COLUMNS,
      payload,
    });
  };

export const fetchTRNApropertiesSingleLink = (id: string) => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionType.FETCH_TRNA_PROPERTIES_SINGLE_LINK,
  });
  try {
    const response = await axios.get(`${process.env.PUBLIC_URL}/api/tRNA-properties?id=${id}`);
    const resData: FetchTRNApropertiesSingleLinkParams = response.data;
    const { dataset, imgURL, summary } = resData;
    const payload = { dataset, id, imgURL, summary };
    dispatch({
      type: ActionType.FETCH_TRNA_PROPERTIES_SINGLE_LINK_COMPLETE,
      payload,
    });
  } catch (error) {
    toast.error(setErrorToastMessage('Data cannot be displayed correctly.'), {
      icon: false,
    });
    const err = error as AxiosError;
    dispatch({
      type: ActionType.FETCH_TRNA_PROPERTIES_SINGLE_LINK_ERROR,
      payload: err.message,
    });
  }
};

export const setTRNApropertiesSingleLinkTableColumns =
  (payload: Column<TableSingleLinkColumnProps>[]) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_TRNA_PROPERTIES_SINGLE_LINK_TABLE_COLUMNS,
      payload,
    });
  };

export const setMRNApropertiesTableColumns =
  (payload: Column<MRNApropertiesElement>[]) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_MRNA_PROPERTIES_TABLE_COLUMNS,
      payload,
    });
  };

export const fetchMRNApropertiesSingleLink = (id: string) => async (dispatch: Dispatch<Action>) => {
  dispatch({
    type: ActionType.FETCH_MRNA_PROPERTIES_SINGLE_LINK,
  });
  try {
    const response = await axios.get(`${process.env.PUBLIC_URL}/api/mRNA-properties?id=${id}`);
    const resData: FetchMRNApropertiesSingleLinkParams = response.data;
    const { dataset, summary } = resData;
    const payload = { dataset, id, summary };
    dispatch({
      type: ActionType.FETCH_MRNA_PROPERTIES_SINGLE_LINK_COMPLETE,
      payload,
    });
  } catch (error) {
    toast.error(setErrorToastMessage('Data cannot be displayed correctly.'), {
      icon: false,
    });
    const err = error as AxiosError;
    dispatch({
      type: ActionType.FETCH_MRNA_PROPERTIES_SINGLE_LINK_ERROR,
      payload: err.message,
    });
  }
};

export const setMRNApropertiesSingleLinkTableColumns =
  (payload: Column<TableSingleLinkColumnProps>[]) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_MRNA_PROPERTIES_SINGLE_LINK_TABLE_COLUMNS,
      payload,
    });
  };

export const setProteinPropertiesSingleLinkTableColumns =
  (payload: Column<TableSingleLinkColumnProps>[]) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_PROTEIN_PROPERTIES_SINGLE_LINK_TABLE_COLUMNS,
      payload,
    });
  };

export const setProteinPropertiesTableColumns =
  (payload: Column<ProteinPropertiesElement>[]) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.SET_PROTEIN_PROPERTIES_TABLE_COLUMNS,
      payload,
    });
  };

export const fetchProteinPropertiesSingleLink =
  (id: string) => async (dispatch: Dispatch<Action>) => {
    dispatch({
      type: ActionType.FETCH_PROTEIN_PROPERTIES_SINGLE_LINK,
    });
    try {
      const response = await axios.get(`${process.env.PUBLIC_URL}/api/protein-properties?id=${id}`);
      const resData: FetchProteinPropertiesSingleLinkParams = response.data;
      const { dataset, summary } = resData;
      const payload = { dataset, id, summary };
      dispatch({
        type: ActionType.FETCH_PROTEIN_PROPERTIES_SINGLE_LINK_COMPLETE,
        payload,
      });
    } catch (error) {
      toast.error(setErrorToastMessage('Data cannot be displayed correctly.'), {
        icon: false,
      });
      const err = error as AxiosError;
      dispatch({
        type: ActionType.FETCH_PROTEIN_PROPERTIES_SINGLE_LINK_ERROR,
        payload: err.message,
      });
    }
  };
