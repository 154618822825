import styled, { css } from 'styled-components';

export const FooterWrapper = styled.footer`
  color: white;
  background-color: hsl(0, 0%, 13%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TextInformation = styled.p`
  text-align: center;
  padding: 5px 15px 0;
`;

export const LogotypeBanner = styled.img`
  width: 100%;
  max-width: 800px;
`;

export const AddressInfo = styled.address`
  text-align: center;
  font-size: 1.8rem;
  padding: 0 10px;

  @media (min-width: ${(props) => props.theme.screenWidthSize.tablet.medium}) {
    font-size: 2.2rem;
  }
`;

export const GrantInfo = styled.p`
  text-align: center;
  font-size: 1.8rem;
  padding: 0 10px;

  @media (min-width: ${(props) => props.theme.screenWidthSize.tablet.medium}) {
    font-size: 2.2rem;
  }
`;

const gradient = css`
  background: #c02425; /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #f0cb35, #c02425); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #f0cb35,
    #c02425
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
`;

export const FancyText = styled.span`
  font-family: 'Cookie', cursive;
  font-size: 28px;
  ${gradient}
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

export const CreatedByLink = styled.a`
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
  padding: 0 15px 0;
  margin-bottom: 5px;
  position: relative;

  @media (min-width: ${(props) => props.theme.screenWidthSize.desktop.XGA}) {
    &::after {
      content: '';
      display: block;
      position: absolute;
      left: 0;
      width: 0;
      bottom: 0;
      right: 0;
      height: 2px;
      ${gradient}
      transition: width 0.3s;
    }

    &:hover::after {
      width: 100%;
    }

    &:hover {
      ${gradient}
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
`;

export const CopyrightWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const UniversityInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (min-width: ${(props) => props.theme.screenWidthSize.desktop.SXGA}) {
    flex-direction: row;
  }
`;

export const HowToCiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const HowToCiteHeader = styled.p``;
