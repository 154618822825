import styled, { css } from 'styled-components';
import bgcImage from '../../../img/dna-bgc.jpg';

interface SectionProps {
  activeFilter?: boolean;
}
export const Section = styled.section<SectionProps>`
  width: 100vw;
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  transition: filter 0.5s;

  background: linear-gradient(90deg, transparent 0, hsl(0, 0%, 100%) 30%), url(${bgcImage});
  background-size: cover;

  @media (min-width: ${(props) => props.theme.screenWidthSize.tablet.large}) {
    background: linear-gradient(90deg, transparent 0, hsl(0, 0%, 100%) 250px), url(${bgcImage});
  }

  ${({ activeFilter }) =>
    activeFilter &&
    css`
      filter: blur(15px) grayscale(1);
    `}
`;
