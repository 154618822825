/* eslint-disable no-param-reassign */
import produce from 'immer';
import { Column } from 'react-table';
import { ActionType } from '../../action-types';
import { Action } from '../../actions';
import { TableSingleLinkColumnProps } from '../../generateXLSXfile';
import { ProteinPropertiesSingleLinkElement } from '../../proteinPropertiesSingleLink';
import { SummaryItem } from '../../tRNApropertiesSingleLink';

export interface ProteinPopertiesSingleLinkState {
  loading: boolean;
  error: string | null;
  id: string | null;
  data: ProteinPropertiesSingleLinkElement[];
  isDataPrepared: boolean;
  columns: {
    selected: string[];
    table: Column<TableSingleLinkColumnProps>[];
  };
  summary: SummaryItem[];
}

export const initialState: ProteinPopertiesSingleLinkState = {
  loading: false,
  error: null,
  id: null,
  data: [],
  isDataPrepared: false,
  columns: {
    selected: [],
    table: [],
  },
  summary: [],
};

const reducer = produce((state: ProteinPopertiesSingleLinkState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.FETCH_PROTEIN_PROPERTIES_SINGLE_LINK:
      state.loading = true;
      return state;
    case ActionType.FETCH_PROTEIN_PROPERTIES_SINGLE_LINK_COMPLETE:
      state.loading = false;
      state.data = action.payload.dataset;
      state.id = action.payload.id;
      state.summary = action.payload.summary;
      state.isDataPrepared = true;
      state.error = null;
      return state;
    case ActionType.FETCH_PROTEIN_PROPERTIES_SINGLE_LINK_ERROR:
      state.loading = false;
      state.error = action.payload;
      state.data = [];
      state.id = null;
      state.summary = [];
      state.isDataPrepared = false;
      return state;
    case ActionType.CLEAR_PROTEIN_PROPERTIES_SINGLE_LINK_DATA:
      state.loading = false;
      state.data = [];
      state.id = null;
      state.summary = [];
      state.isDataPrepared = false;
      state.error = null;
      return state;
    case ActionType.SET_PROTEIN_PROPERTIES_SINGLE_LINK_SELECTED_COLUMNS:
      state.columns.selected = action.payload;
      return state;
    case ActionType.SET_PROTEIN_PROPERTIES_SINGLE_LINK_TABLE_COLUMNS:
      state.columns.table = action.payload;
      return state;
    default:
      return state;
  }
});

export default reducer;
