/* eslint-disable object-curly-newline */
import * as React from 'react';
import styled from 'styled-components';
import genomeGif from '../../../../img/genome_mtDNA.gif';

const CircleImg = styled.img`
  display: none;

  @media (min-width: ${({ theme }) => theme.screenWidthSize.tablet.large}) and (min-height: ${({
      theme,
    }) => theme.screenHeightSize.small}) {
    display: block;
    width: 50%;
    max-width: 680px;
    position: absolute;
    right: 0;
    top: 40%;
    transform: translateY(-50%);
  }

  @media (min-width: ${({ theme }) => theme.screenWidthSize.desktop.WXGA}) {
    right: 15%;
  }

  @media (max-height: ${({ theme }) => theme.screenHeightSize.medium}) {
    max-width: 75vh;
  }
`;

export interface StyledCircleImageProps {}
export const StyledCircleImage: React.FC<StyledCircleImageProps> = () => (
  <CircleImg src={genomeGif} />
);
