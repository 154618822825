/* eslint-disable no-param-reassign */
import produce from 'immer';
import { ActionType } from '../../action-types';
import { Action } from '../../actions';

interface DnaLoaderState {
  mainLoading: boolean;
  apiLoading: boolean;
}

export const initialState: DnaLoaderState = {
  mainLoading: true,
  apiLoading: false,
};

const reducer = produce((state: DnaLoaderState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.ENABLE_MAIN_LOADING:
      state.mainLoading = true;
      return state;
    case ActionType.DISABLE_MAIN_LOADING:
      state.mainLoading = false;
      return state;
    case ActionType.ENABLE_API_LOADING:
      state.apiLoading = true;
      return state;
    case ActionType.DISABLE_API_LOADING:
      state.apiLoading = false;
      return state;
    default:
      return state;
  }
});

export default reducer;
