import * as React from 'react';
import { Footer } from '../../templates';
import { StyledLink, ContentFlexWrapper, ErrorSection, ErrorHeader } from '../../UI/atoms';
import { useActions } from '../../../hooks/use-actions';
import { HomeLink, StyledIcon } from './style';

export interface NotFound404Props {}
export const NotFound404: React.FC<NotFound404Props> = () => {
  const { disableDnaMainLoader, enableDnaMainLoader } = useActions();
  disableDnaMainLoader();
  // 🚀 used as ComponentWillUnmount 🚀
  React.useLayoutEffect(
    () => () => {
      enableDnaMainLoader();
    },
    [],
  );
  return (
    <ErrorSection>
      <ContentFlexWrapper flexGrow color="white" flexCenter customFontFamily darkBackground>
        <ErrorHeader>404 - Page not found</ErrorHeader>
        <HomeLink>
          <StyledIcon />
          <StyledLink
            to="/"
            label="Back to main page"
            color="white"
            hoverColor="hsl(190, 100%, 50%)"
          />
        </HomeLink>
      </ContentFlexWrapper>
      <Footer />
    </ErrorSection>
  );
};
