import { IconType } from 'react-icons';
import { AiOutlineMail } from 'react-icons/ai';
import { BiRefresh } from 'react-icons/bi';
import styled, { css, DefaultTheme, StyledComponent } from 'styled-components';

const getCornerViewStyles = (
  hoverScale: number,
  hoverTranslateY: number,
  styledIcon: StyledComponent<IconType, DefaultTheme, {}, never>,
) => css`
  padding: 5px 20px 5px 40px;
  margin-right: 10px;
  font-size: 2rem;
  font-family: ${({ theme }) => theme.fontFamily.header};
  position: relative;
  background: transparent;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-size 0.3s;

  background: linear-gradient(to right, white 3px, transparent 3px) 0 0,
    linear-gradient(to right, white 3px, transparent 3px) 0 100%,
    linear-gradient(to left, white 3px, transparent 3px) 100% 0,
    linear-gradient(to left, white 3px, transparent 3px) 100% 100%,
    linear-gradient(to bottom, white 3px, transparent 3px) 0 0,
    linear-gradient(to bottom, white 3px, transparent 3px) 100% 0,
    linear-gradient(to top, white 3px, transparent 3px) 0 100%,
    linear-gradient(to top, white 3px, transparent 3px) 100% 100%;
  background-repeat: no-repeat;
  background-size: 10px 10px;

  &:hover {
    background-size: 20px 20px;

    ${styledIcon} {
      transform: scale(${hoverScale}) translateY(${hoverTranslateY}%);
      transform-origin: center center;
    }
  }
`;

const getIconStyles = (left: number, translateY: number) => css`
  font-size: 2.6rem;
  position: absolute;
  top: 50%;
  left: ${left}px;
  transform: translateY(${translateY}%);
  transition: transform 0.3s;
`;

export const Description = styled.p`
  font-family: ${({ theme }) => theme.fontFamily.main};
  font-size: 2rem;
`;

export const ButtonWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const StyledMailIcon = styled(AiOutlineMail)`
  ${getIconStyles(8, -55)}
`;

export const StyledRefreshIcon = styled(BiRefresh)`
  ${getIconStyles(6, -57)}
`;

export const RefreshButton = styled.button`
  ${getCornerViewStyles(1.2, -43, StyledRefreshIcon)}
`;

export const IssueLink = styled.a`
  ${getCornerViewStyles(1.1, -46, StyledMailIcon)}
  text-decoration: none;
`;

export const ButtonLabel = styled.span``;
