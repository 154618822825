/* eslint-disable object-curly-newline */
/* eslint-disable no-param-reassign */
import produce from 'immer';
import { ActionType } from '../../action-types';
import { Action } from '../../actions';
import { DictionariesData } from '../../dictionaries';

export interface ErrorInterface {
  code?: number | null;
  serverResponse: string | null;
  clientResponse?: string | null;
}

export interface DictionariesState {
  loading: boolean;
  error: ErrorInterface;
  data: DictionariesData;
  isDataPrepared: boolean;
}

export const initialState: DictionariesState = {
  loading: false,
  error: {
    code: null,
    serverResponse: null,
    clientResponse: null,
  },
  data: {
    canisSNPFinder: {},
    tRNAproperties: {},
    tRNApropertiesSingleLink: {},
    mRNAproperties: {},
    mRNApropertiesSingleLink: {},
    proteinProperties: {},
    proteinPropertiesSingleLink: {},
  },
  isDataPrepared: false,
};

const reducer = produce((state: DictionariesState = initialState, action: Action) => {
  switch (action.type) {
    case ActionType.FETCH_DICTIONARIES:
      state.loading = true;
      state.error = {
        code: null,
        serverResponse: null,
        clientResponse: null,
      };
      return state;
    case ActionType.FETCH_DICTIONARIES_COMPLETE:
      state.loading = false;
      state.data = action.payload;
      state.isDataPrepared = true;
      return state;
    case ActionType.FETCH_DICTIONARIES_ERROR:
      state.loading = false;
      state.error = {
        code: action.payload.code,
        clientResponse: action.payload.clientResponse,
        serverResponse: action.payload.serverResponse,
      };
      return state;
    default:
      return state;
  }
});

export default reducer;
