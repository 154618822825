import * as React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  background-color: white;
  width: 90%;
  height: 70vh;
  max-height: 720px;
  margin: 0 auto;
  overflow: auto;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);

  @media (min-width: ${({ theme }) => theme.screenWidthSize.tablet.small}) {
    height: auto;
    width: auto;
    margin: 0 15px;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;
  }
`;

const StyledImage = styled.img`
  @media (min-width: ${({ theme }) => theme.screenWidthSize.tablet.small}) {
    min-width: 300px;
    width: 100%;
    max-width: 1280px;
  }
`;

export interface MoleculeStructureProps {
  src: string;
  alt: string;
}
export const MoleculeStructure: React.FC<MoleculeStructureProps> = ({
  src,
  alt,
}: MoleculeStructureProps) => (
  <Wrapper>
    <StyledImage src={src} alt={alt} />
  </Wrapper>
);
