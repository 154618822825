import styled from 'styled-components';
import * as React from 'react';
import { StyledLinkProps } from '../../atoms';
import { StyledListItem } from '../../molecules';

const List = styled.ul`
  padding-top: 5px;
  padding-left: 20%;
  list-style-type: none;

  @media (min-width: ${(props) => props.theme.screenWidthSize.tablet.large}) {
    padding-top: 100px;
    padding-left: 200px;
  }
`;

interface StyledMenuListProps {
  links: StyledLinkProps[];
}

export const StyledMenuList: React.FC<StyledMenuListProps> = ({ links }: StyledMenuListProps) => (
  <List>
    {links.map(({ to, label }) => (
      <StyledListItem key={to} to={to} label={label} />
    ))}
  </List>
);
