import styled, { css } from 'styled-components';

interface SingleLinkWrapperProps {
  centerContent?: true;
}
export const SingleLinkWrapper = styled.div<SingleLinkWrapperProps>`
  @media (min-width: ${({ theme }) => theme.screenWidthSize.desktop.WXGA}) {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-start;
    margin: 0 15px;
  }

  ${({ centerContent, theme }) =>
    centerContent &&
    css`
      @media (min-width: ${theme.screenWidthSize.desktop.HD}) {
        width: ${theme.screenWidthSize.desktop.HD};
        margin: 0 auto;
      }
    `}
`;
