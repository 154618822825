import { TiHome } from 'react-icons/ti';
import styled from 'styled-components';

export const StyledIcon = styled(TiHome)`
  font-size: 32px;
`;

export const HomeLink = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
