import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AiFillHome } from 'react-icons/ai';
import { BsArrowLeftShort } from 'react-icons/bs';

const StyledHomeIcon = styled(AiFillHome)``;

const StyledArrowIcon = styled(BsArrowLeftShort)`
  font-size: 3.5rem;
  font-weight: bold;
  padding-right: 3px;
  transform: scale(1.3);
  transition: transform 0.2s;
`;

const SingleLink = styled(Link)`
  z-index: 1;
  overflow: hidden;
  text-decoration: none;
  font-size: 4rem;
  color: ${({ theme }) => theme.colorsPallete.blue};
  padding: 7px;
  background-color: hsla(0, 0%, 100%, 0.8);
  box-shadow: 0 0 1px #bbb;
  width: 62px;
  height: 32px;

  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;

  position: fixed;
  left: 15px;
  top: 15px;

  transition: background-color 0.4s;

  &:hover {
    background-color: hsla(0, 0%, 100%, 1);
  }

  &:hover ${StyledArrowIcon} {
    transform: translateX(-100%) scale(1.3);
  }
`;

export interface PreviousPageBtnProps {
  to: string;
}

export const PreviousPageBtn: React.FC<PreviousPageBtnProps> = ({ to }: PreviousPageBtnProps) => (
  <SingleLink to={to}>
    <StyledArrowIcon />
    <StyledHomeIcon />
  </SingleLink>
);
