/* eslint-disable object-curly-newline */
import * as React from 'react';
import { useActions } from '../../../hooks/use-actions';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { InternalServerError500 } from '../../pages';

export interface WithLoadingDictionariesProps {
  Component: React.ComponentType;
}

export const WithLoadingDictionaries: React.FC<WithLoadingDictionariesProps> = ({
  Component,
}: WithLoadingDictionariesProps) => {
  const { fetchDictionaries, disableDnaMainLoader } = useActions();
  const dictionariesState = useTypedSelector(({ dictionaries }) => dictionaries);
  const errorsState = useTypedSelector(({ errors }) => errors);

  React.useEffect(() => {
    if (!dictionariesState?.isDataPrepared) {
      fetchDictionaries();
      // TODO: use indexedDB to cache dictionariesState data ✏️
    }
  }, []);

  React.useEffect(() => {
    if (!dictionariesState?.loading && dictionariesState?.isDataPrepared) {
      disableDnaMainLoader();
    }
  }, [dictionariesState?.loading]);

  if (errorsState?.isInternalServerError500) {
    return <InternalServerError500 />;
  }
  return <Component />;
};
