import * as React from 'react';
import { Footer } from '..';
import { ContentFlexWrapper } from '../../UI/atoms';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { Section } from './style';

// Fix mobile 100vh
const handleResize = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export interface PageTemplateProps {
  children: React.ReactNode;
}
export const PageTemplate: React.FC<PageTemplateProps> = ({ children }) => {
  const dnaLoaderState = useTypedSelector(({ dnaLoader }) => dnaLoader);

  handleResize();
  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  return (
    <Section activeFilter={dnaLoaderState?.apiLoading}>
      <ContentFlexWrapper flexDirection="column" flexGrow>
        {children}
      </ContentFlexWrapper>
      <Footer />
    </Section>
  );
};
