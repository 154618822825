import * as React from 'react';
import styled from 'styled-components';
import { AvailableFormIDs, AvailableFormNames } from '../../../../global/types';
import { ContentFlexWrapper, ErrorMessage } from '..';

export interface radioElement {
  name: AvailableFormNames;
  id: AvailableFormIDs;
  label: string;
}

const StyledInput = styled.input`
  margin-right: 5px;
  cursor: pointer;
`;

const StyledLabel = styled.label`
  margin-right: 25px;
  cursor: pointer;
`;

const StyledParagraph = styled.p``;

const StyledDescription = styled.span``;

export interface StyledRadioInputProps {
  radioOptions: radioElement[];
  paragraphDescription: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string | null;
}

export const StyledRadioInput: React.FC<StyledRadioInputProps> = ({
  radioOptions,
  paragraphDescription,
  onChange,
  error,
}: StyledRadioInputProps) => (
  <>
    <StyledParagraph>{paragraphDescription}</StyledParagraph>
    <ContentFlexWrapper
      alignItems="center"
      justifyContent="flex-start"
      flexDirection="row"
      bottomMargin
      flexWrap="wrap"
    >
      {radioOptions.map((option) => (
        <StyledLabel key={option.id} htmlFor={option.id}>
          <StyledInput
            type="radio"
            name={option.name}
            id={option.id}
            value={option.id}
            onChange={onChange}
          />
          <StyledDescription>{option.label}</StyledDescription>
        </StyledLabel>
      ))}
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </ContentFlexWrapper>
  </>
);
