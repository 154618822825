import * as React from 'react';
import { DescriptionParagraph, CustomTable, CustomizeTableRow } from '../../UI/molecules';
import { PageTemplate } from '../../templates';
import { MainHeader, PreviousPageBtn } from '../../UI/atoms';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { RoutingLabels, RoutingURLs } from '../../../routing';
import { useActions } from '../../../hooks/use-actions';
import { getCellCustomStyle } from '../../../utils';
import { AvailableMRNAColumnIDs, MRNApropertiesElement } from '../../../state/mRNAproperties';

export interface MRNApropertiesProps {}
export const MRNAproperties: React.FC<MRNApropertiesProps> = () => {
  const mRNApropertiesState = useTypedSelector(({ mRNAproperties }) => mRNAproperties);
  const dictionariesState = useTypedSelector(({ dictionaries }) => dictionaries);
  const generateXLSXfileState = useTypedSelector(({ generateXLSXfile }) => generateXLSXfile);
  const { setMRNApropertiesTableColumns, downloadXLSXFile } = useActions();

  const isDownloadingXLSXfile = generateXLSXfileState?.loading || false;

  const tableDataset = React.useMemo<MRNApropertiesElement[]>(
    () => mRNApropertiesState?.data || [],
    [mRNApropertiesState?.data],
  );

  React.useEffect(() => {
    document.title = RoutingLabels.MRNA_PROPERTIES;
  }, []);

  const handleDownloadXLSXfile = () => {
    if (generateXLSXfileState?.loading) return; // 🔥 do not fetch API call when active 🔥
    const tableColumns = mRNApropertiesState?.columns.table || [];
    const data = mRNApropertiesState?.data || [];
    const name = RoutingLabels.MRNA_PROPERTIES;
    downloadXLSXFile({
      tableColumns,
      data,
      name,
    });
  };

  const customizeTableRow: CustomizeTableRow[] = [
    {
      id: AvailableMRNAColumnIDs.GENE,
      customStyle: 'justify-content: flex-start; padding-left: 5px',
      pageLink: {
        active: true,
        availableLinks: mRNApropertiesState?.geneLinks || new Map<string, string>(),
        mainURL: RoutingURLs.MRNA_PROPERTIES,
      },
    },
    {
      id: AvailableMRNAColumnIDs.PERCENTAGE_GENE_HOMOLOGY,
      dynamicCustomStyle: getCellCustomStyle,
    },
  ];

  const dictColumnWidth = {
    [AvailableMRNAColumnIDs.LP]: 35,
    [AvailableMRNAColumnIDs.GENE]: 60,
    [AvailableMRNAColumnIDs.ABBREVIATION]: 170,
  };

  return (
    <>
      {mRNApropertiesState?.isDataPrepared && (
        <PageTemplate>
          <PreviousPageBtn to="/" />
          <MainHeader>{RoutingLabels.MRNA_PROPERTIES}</MainHeader>
          <DescriptionParagraph disableReadMoreBtn>
            Choose a protein-coding gene of interest from the list below to see the comparison and
            the secondary structure of canine and human protein-coding genes:
          </DescriptionParagraph>
          {dictionariesState?.data.mRNAproperties.columnNames && (
            <CustomTable
              data={tableDataset}
              columnParams={{
                selected: mRNApropertiesState.columns.selected,
                table: mRNApropertiesState.columns.table,
                setTableColumns: setMRNApropertiesTableColumns,
                dictColumnNames: dictionariesState?.data.mRNAproperties.columnNames,
                dictColumnWidth,
              }}
              generateXLSX={{
                handleDownload: handleDownloadXLSXfile,
                isDownloading: isDownloadingXLSXfile,
              }}
              customizeTableRow={customizeTableRow}
            />
          )}
        </PageTemplate>
      )}
    </>
  );
};
