import { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  fontFamily: {
    main: "'Oswald', sans-serif;",
    header: "'Bebas Neue', cursive;",
  },
  colorsPallete: {
    blue: 'hsl(216, 53%, 28%)',
  },
  screenWidthSize: {
    desktop: {
      hdTV: '1366px',
      fullHD: '1920px',
      HD: '1600px',
      WXGA: '1440px',
      SXGA: '1280px',
      XGA: '1024px',
    },
    mobile: {
      small: '320px',
      medium: '360px',
      large: '414px',
    },
    tablet: {
      small: '601px',
      medium: '768px',
      large: '800px',
      xlarge: '962px',
    },
  },
  screenHeightSize: {
    small: '630px',
    medium: '960px',
  },
  textColors: {
    orange: 'rgb(237,125,49)',
    brown: 'rgb(192,0,0)',
    red: 'rgb(255,0,0)',
    green: 'rgb(0,176,80)',
    yellow: 'rgb(255,192,0)',
  },
};

export { theme };
