import styled from 'styled-components';

export const ErrorHeader = styled.h1`
  font-size: 5rem;
  text-align: center;
  font-weight: normal;

  @media (min-width: ${(props) => props.theme.screenWidthSize.tablet.medium}) {
    font-size: 7rem;
  }

  @media (min-width: ${(props) => props.theme.screenWidthSize.desktop.XGA}) {
    font-size: 9rem;
  }
`;
