/* eslint-disable object-curly-newline */
import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
  CustomizeTableRow,
  CustomLegendColor,
  CustomTable,
  CustomTextColor,
} from '../../UI/molecules';
import { PageTemplate } from '../../templates';
import { MainHeader, PreviousPageBtn, SingleLinkWrapper, SummaryLegendList } from '../../UI/atoms';
import { useTypedSelector } from '../../../hooks/use-typed-selector';
import { RoutingURLs } from '../../../routing';
import { useActions } from '../../../hooks/use-actions';
import { getMapKeyByValue } from '../../../utils';
import { UrlParams } from '../../../state/proteinProperties';
import {
  AvailableProteinSingleLinkIDs,
  ProteinPropertiesSingleLinkElement,
} from '../../../state/proteinPropertiesSingleLink';

export interface ProteinPropertiesSingleLinkProps {}
export const ProteinPropertiesSingleLink: React.FC<ProteinPropertiesSingleLinkProps> = () => {
  const proteinPropertiesState = useTypedSelector(({ proteinProperties }) => proteinProperties);
  const proteinPropertiesSingleLinkState = useTypedSelector(
    ({ proteinPropertiesSingleLink }) => proteinPropertiesSingleLink,
  );
  const dictionariesState = useTypedSelector(({ dictionaries }) => dictionaries);
  const generateXLSXfileState = useTypedSelector(({ generateXLSXfile }) => generateXLSXfile);
  const {
    fetchProteinPropertiesSingleLink,
    setProteinPropertiesSingleLinkTableColumns,
    downloadXLSXFile,
    enableDnaAPILoader,
    disableDnaAPILoader,
  } = useActions();
  const { linkID }: UrlParams = useParams();

  const currentID =
    proteinPropertiesState?.geneLinks &&
    getMapKeyByValue(proteinPropertiesState?.geneLinks, linkID);

  React.useEffect(() => {
    if (linkID !== proteinPropertiesSingleLinkState?.id && currentID) {
      fetchProteinPropertiesSingleLink(currentID);
    }
    if (currentID) document.title = currentID;
  }, [proteinPropertiesSingleLinkState?.id, currentID]);

  React.useEffect(() => {
    if (proteinPropertiesSingleLinkState?.loading) enableDnaAPILoader();
    else disableDnaAPILoader();
  }, [proteinPropertiesSingleLinkState?.loading]);

  const tableDataset = React.useMemo<ProteinPropertiesSingleLinkElement[]>(
    () => proteinPropertiesSingleLinkState?.data || [],
    [proteinPropertiesSingleLinkState?.data],
  );

  const handleDownloadXLSXfile = () => {
    if (generateXLSXfileState?.loading) return; // 🔥 do not fetch API call when active 🔥
    const tableColumns = proteinPropertiesSingleLinkState?.columns.table || [];
    const data = proteinPropertiesSingleLinkState?.data || [];
    const name = (currentID && proteinPropertiesState?.geneLinks.get(currentID)) || '';
    const mergedFirstRowHeaderCells = true;
    downloadXLSXFile({
      tableColumns,
      data,
      name,
      mergedFirstRowHeaderCells,
    });
  };

  const isDownloadingXLSXfile = generateXLSXfileState?.loading || false;

  const width = 200;
  const dictColumnWidth = {
    [AvailableProteinSingleLinkIDs.DOG_AMINOACID_PROPERTIES]: width,
    [AvailableProteinSingleLinkIDs.HUMAN_AMINOACID_PROPERTIES]: width,
    [AvailableProteinSingleLinkIDs.SIMILARITY]: width,
  };

  enum SIMILARITY_VALUES {
    IDENTICAL_AMINOACID = 'identical',
    NONCONSERVATIVE_REPLACEMENT = 'nonconservative replacement',
    CONSERVATIVE_REPLACEMENT = 'conservative replacement',
  }

  const getCustomTextColor = (values: ProteinPropertiesSingleLinkElement) => {
    let color: CustomTextColor | CustomLegendColor | null = null;
    if (values.similarity === SIMILARITY_VALUES.IDENTICAL_AMINOACID) {
      color = 'green';
    } else if (values.similarity === SIMILARITY_VALUES.CONSERVATIVE_REPLACEMENT) {
      color = 'yellow';
    } else if (values.similarity === SIMILARITY_VALUES.NONCONSERVATIVE_REPLACEMENT) {
      color = 'red';
    }
    return color;
  };

  const customizeTableRow: CustomizeTableRow[] = [
    {
      id: AvailableProteinSingleLinkIDs.SIMILARITY,
      getCustomTextColor,
    },
  ];

  return (
    <>
      <PageTemplate>
        <PreviousPageBtn to={`/${RoutingURLs.PROTEIN_PROPERTIES}`} />
        <MainHeader>{currentID || 'protein'}</MainHeader>
        {proteinPropertiesSingleLinkState?.loading ? null : (
          <SingleLinkWrapper centerContent>
            {proteinPropertiesSingleLinkState?.summary && (
              <SummaryLegendList
                margin="0 auto"
                width="260px"
                summary={proteinPropertiesSingleLinkState.summary}
              />
            )}
            {proteinPropertiesSingleLinkState?.columns.selected &&
              dictionariesState?.data.proteinPropertiesSingleLink.columnNames && (
                <CustomTable
                  data={tableDataset}
                  columnParams={{
                    selected: proteinPropertiesSingleLinkState?.columns.selected,
                    table: proteinPropertiesSingleLinkState.columns.table,
                    setTableColumns: setProteinPropertiesSingleLinkTableColumns,
                    dictColumnNames:
                      dictionariesState?.data.proteinPropertiesSingleLink.columnNames,
                    dictColumnWidth,
                  }}
                  customizeTableRow={customizeTableRow}
                  generateXLSX={{
                    handleDownload: handleDownloadXLSXfile,
                    isDownloading: isDownloadingXLSXfile,
                  }}
                />
              )}
          </SingleLinkWrapper>
        )}
      </PageTemplate>
    </>
  );
};
