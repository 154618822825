export enum RoutingURLs {
  CANIS_SNP_FINDER = 'canis-snp-finder',
  TRNA_PROPERTIES = 'tRNA-properties',
  MRNA_PROPERTIES = 'mRNA-properties',
  PROTEIN_PROPERTIES = 'protein-properties',
}

export enum RoutingLabels {
  CANIS_SNP_FINDER = 'Canis SNP finder',
  TRNA_PROPERTIES = 'tRNA properties',
  MRNA_PROPERTIES = 'mRNA properties',
  PROTEIN_PROPERTIES = 'Protein properties',
}
